<template>
    <div class="wrap-like">

        <div class="host-likes">
            <div class="likes" :class="{liked}" @click.prevent="setlike">{{likes}}</div>
        </div>
        <div class="host-onmap">
            <div :class="{hidden: !onmap}" class="onmap" @click.prevent="onmap"></div>
        </div>
        <div class="host-transit">
            <div :class="{hidden: +transitParam===+$route.params.id}" class="transit" @click.prevent="transit"></div>
        </div>
        <div class="host-join">
            <slot name="joinbtn"></slot>
        </div>    
    </div>
</template>

<script>
export default {
    name: 'LikeBar',

    computed:{

    },

    props:{
        likes: {
            type: Number,
            default: ()=>0,
        },
        liked: {
            type: Boolean,
            default: ()=> false,
        },
        

        setlike:{
            type: Function,
            default: () => {}
        },
        transit:{
            type: Function,
            default: () => {}
        },
        onmap:{
            type: Function,
            default: () => null
        },
        transitParam:{
            type: String,
            default: () => null


        }



    },

}
</script>

<style lang="scss" scoped>
    .wrap-like{
        margin-top: 24px;
        padding-bottom: 8px;

        font-size: 120%;
        display: flex;

        >*{
            flex: 1;
            .hidden{
                display: none;
            }

            text-align: center;
            >*{
                max-width: 40px;
                margin: auto;

            }
        }


        .likes{

            &.liked{
                transform: scale(1.1);

                &::before{
                    text-shadow: 1px 1px black;
                }

            }

            &::before{
            
                content: "\F164";
                font-family: "Font Awesome 5 Free";
                color: yellow;
                margin-right: 6px;
            }
        }

        .onmap{

            &::before{
            
                content: "\F3C5";
                font-family: "Font Awesome 5 Free";
                color: red;
            }
        }

        .transit{
            &::before{
            
                content: "\F30B";
                font-family: "Font Awesome 5 Free";
                color: white;
                padding: 2px;   
                border: 1px solid white;
            }
        }

        .join{
            
            margin: auto;
            &::before{
            
                content: "\F067";
                font-family: "Font Awesome 5 Free";
                color: white;
                margin-right: 6px;
                padding: 2px;   
                border: 1px solid white;

            }
        }

    }

    .host-transit{
        display: none;
    }
    .host-likes {
        flex: 3;
        .likes{
            margin-left: 35px;
        }
    }
    .host-join .join{
        margin-right: 20px;
    }
</style>