<template>
  <div class="theme" v-if="title">
    <div class="title">{{ title }}</div>
    <div class="rainbow">
      <span
        v-for="(pal) of paletteTop10"
        v-bind:key="pal.pattern"
        :class="
          'color ' + pal.pattern + (value === pal.pattern ? ' active' : '')
        "
        @click="doSetter(pal)"
      ></span>
    </div>

    <template v-if="1==1||!premiumAccess">
        <div class="premium-title">
            <span>Премиум палитра</span>
        </div>
    </template>

    <div class="rainbow r-other" :class="{short: !spoilered1, 'is-premium': !premiumAccess}">
      <span
        v-for="(pal) of paletteOther"
        v-bind:key="pal.pattern"
        :class="
          'color ' + pal.pattern + (value === pal.pattern ? ' active' : '')
        "
        @click="doSetter(pal)"
      ></span>
    
    </div>
    <template v-if="!spoilered1 && paletteOther.length > 20">
        <div style="text-align: right; width: 90%;" :class="{'is-premium': !premiumAccess}">
            <span @click="doSpoiler1">показать еще</span>
        </div>
    </template>

    <template v-if="spoilered1 && paletteOther.length > 20">
        <div style="text-align: right; width: 90%;">
            <span @click="doHideSpoiler1">скрыть</span>
        </div>
    </template>

    <template v-if="premiumTitle">
        <br>
        <div class="title premium-title">{{ premiumTitle }}</div>
        <div class="rainbow" :class="{short: !spoilered2, 'is-premium': !premiumAccess}">
        <span
            v-for="(pal) of palettePicture"
            v-bind:key="pal.pattern"
            :class="
            'color pre-' + pal.pattern + (value === pal.pattern ? ' active' : '')
            "
            @click="doSetter(pal)"
        ></span>
        
        </div>

        <template v-if="!spoilered2 && palettePicture.length > 20">
            <div style="text-align: right; width: 90%;" :class="{'is-premium': !premiumAccess}">
                <span @click="doSpoiler2">показать еще</span>
            </div>
        </template>

        <template v-if="spoilered2 && palettePicture.length > 20">
            <div style="text-align: right; width: 90%;">
                <span @click="doHideSpoiler2">скрыть</span>
            </div>
        </template>

    </template>
    

    <GetPremiumModal />
  </div>
</template>


<script>
import { MxStore } from '@/assets/js/mix/MxStore';
import GetPremiumModal from "@/components/modal/GetPremiumModal.vue"
export default {
  name: "Rainbow",

  mixins: [MxStore],
  data: ()=>({
      spoilered1: false,
      spoilered2: false,
  }),
  components: {GetPremiumModal},

  computed: {
    palette: function () {
      return this[this.palete];
    },
    paletteTop10: function () {
      if(this.premiumTitle){
          return this[this.palete].filter(i=>!i.picture).slice(0,10);

      }
      return this[this.palete].slice(0,10);
    },
    paletteOther: function () {
      if(this.premiumTitle){
      }
      return this[this.palete].filter(i=>!i.picture).slice(10);
    },

    palettePicture: function () {
      if(this.premiumTitle){
          return this[this.palete].filter(i=>i.picture);
      }
      return [];
      
    },
    

    buttons: () => [
      { pattern: "user-color-1" },
      { pattern: "user-color-2" },
      { pattern: "user-color-3" },
      { pattern: "user-color-4" },
      { pattern: "user-color-5" },
      { pattern: "user-color-6" },
      { pattern: "user-color-7" },
      { pattern: "user-color-8" },
      { pattern: "user-color-9" },
      { pattern: "user-color-10" },
    ],
    backgrounds: () => [
      { pattern: "user-bg-color-1" },
      { pattern: "user-bg-color-2" },
      { pattern: "user-bg-color-3" },
      { pattern: "user-bg-color-4" },
      { pattern: "user-bg-color-5" },
      { pattern: "user-bg-color-6" },
      { pattern: "user-bg-color-7" },
      { pattern: "user-bg-color-8" },
      { pattern: "user-bg-color-9" },
      { pattern: "user-bg-color-10" },
    ],

    userButtons: () => [
      { pattern: "user-color-1" },
      { pattern: "user-color-2" },
      { pattern: "user-color-3" },
      { pattern: "user-color-4" },
      { pattern: "user-color-5" },
      { pattern: "user-color-6" },
      { pattern: "user-color-7" },
      { pattern: "user-color-8" },
      { pattern: "user-color-9" },
      { pattern: "user-color-10" },

      { pattern: "user-color-18", premium: true },
      { pattern: "user-color-19", premium: true },
      { pattern: "user-color-20", premium: true },
      { pattern: "user-color-21", premium: true },
      { pattern: "user-color-22", premium: true },
      { pattern: "user-color-23", premium: true },
      { pattern: "user-color-24", premium: true },
      { pattern: "user-color-25", premium: true },
      { pattern: "user-color-26", premium: true },
      { pattern: "user-color-27", premium: true },
      { pattern: "user-color-28", premium: true },
      { pattern: "user-color-29", premium: true },
      { pattern: "user-color-30", premium: true },
      { pattern: "user-color-31", premium: true },
      { pattern: "user-color-32", premium: true },
      { pattern: "user-color-33", premium: true },
      { pattern: "user-color-34", premium: true },
      { pattern: "user-color-35", premium: true },
      { pattern: "user-color-36", premium: true },
      { pattern: "user-color-37", premium: true },
      { pattern: "user-color-38", premium: true },
      { pattern: "user-color-39", premium: true },
      { pattern: "user-color-40", premium: true },
      { pattern: "user-color-41", premium: true },
      { pattern: "user-color-42", premium: true },
      { pattern: "user-color-43", premium: true },
      { pattern: "user-color-44", premium: true },
      { pattern: "user-color-45", premium: true },
      { pattern: "user-color-46", premium: true },
      { pattern: "user-color-47", premium: true },
      { pattern: "user-color-48", premium: true },
      { pattern: "user-color-49", premium: true },
      { pattern: "user-color-50", premium: true },
      { pattern: "user-color-51", premium: true },
      { pattern: "user-color-52", premium: true },
      { pattern: "user-color-53", premium: true },
      { pattern: "user-color-54", premium: true },
      { pattern: "user-color-55", premium: true },
      { pattern: "user-color-56", premium: true },
      { pattern: "user-color-57", premium: true },
      { pattern: "user-color-58", premium: true },
      { pattern: "user-color-59", premium: true },
      { pattern: "user-color-60", premium: true },
      { pattern: "user-color-61", premium: true },
      { pattern: "user-color-62", premium: true },
      { pattern: "user-color-63", premium: true },
      { pattern: "user-color-64", premium: true },
      { pattern: "user-color-65", premium: true },
      { pattern: "user-color-66", premium: true },
      { pattern: "user-color-67", premium: true },
      { pattern: "user-color-68", premium: true },
      { pattern: "user-color-69", premium: true },
      { pattern: "user-color-70", premium: true },
      { pattern: "user-color-71", premium: true },
      { pattern: "user-color-72", premium: true },
      { pattern: "user-color-73", premium: true },
      { pattern: "user-color-74", premium: true },
      { pattern: "user-color-75", premium: true },
      { pattern: "user-color-76", premium: true },
      { pattern: "user-color-77", premium: true },
      { pattern: "user-color-78", premium: true },
      { pattern: "user-color-79", premium: true },
      { pattern: "user-color-80", premium: true },
      { pattern: "user-color-81", premium: true },
      { pattern: "user-color-82", premium: true },
      { pattern: "user-color-83", premium: true },
      { pattern: "user-color-84", premium: true },
      { pattern: "user-color-85", premium: true },
      { pattern: "user-color-86", premium: true },
      { pattern: "user-color-87", premium: true },
      { pattern: "user-color-88", premium: true },
      { pattern: "user-color-89", premium: true },
      { pattern: "user-color-90", premium: true },
      { pattern: "user-color-91", premium: true },
      { pattern: "user-color-92", premium: true },
      { pattern: "user-color-93", premium: true },
      { pattern: "user-color-94", premium: true },
      { pattern: "user-color-95", premium: true },
      { pattern: "user-color-96", premium: true },
      { pattern: "user-color-97", premium: true },
      { pattern: "user-color-98", premium: true },
      { pattern: "user-color-99", premium: true },
      { pattern: "user-color-100", premium: true },
      { pattern: "user-color-101", premium: true },
      { pattern: "user-color-102", premium: true },
      { pattern: "user-color-103", premium: true },
      { pattern: "user-color-104", premium: true },
      { pattern: "user-color-105", premium: true },
      { pattern: "user-color-106", premium: true },
      { pattern: "user-color-107", premium: true },
      { pattern: "user-color-108", premium: true },
      { pattern: "user-color-109", premium: true },
      { pattern: "user-color-110", premium: true },
      { pattern: "user-color-111", premium: true },
      { pattern: "user-color-112", premium: true },
      { pattern: "user-color-113", premium: true },
      { pattern: "user-color-114", premium: true },
      { pattern: "user-color-115", premium: true },
      { pattern: "user-color-116", premium: true },
      { pattern: "user-color-117", premium: true },
      { pattern: "user-color-118", premium: true },
      { pattern: "user-color-119", premium: true },
      { pattern: "user-color-120", premium: true },
      { pattern: "user-color-121", premium: true },
      { pattern: "user-color-122", premium: true },
      { pattern: "user-color-123", premium: true },
      { pattern: "user-color-124", premium: true },
      { pattern: "user-color-125", premium: true },
      { pattern: "user-color-126", premium: true },
      { pattern: "user-color-127", premium: true },
      { pattern: "user-color-128", premium: true },
      { pattern: "user-color-129", premium: true },
      { pattern: "user-color-130", premium: true },
      { pattern: "user-color-131", premium: true },
      { pattern: "user-color-132", premium: true },
      { pattern: "user-color-133", premium: true },
      { pattern: "user-color-134", premium: true },
      { pattern: "user-color-135", premium: true },
      { pattern: "user-color-136", premium: true },
      { pattern: "user-color-137", premium: true },
      { pattern: "user-color-138", premium: true },
      { pattern: "user-color-139", premium: true },
      { pattern: "user-color-140", premium: true },
      { pattern: "user-color-141", premium: true },
      { pattern: "user-color-142", premium: true },
      { pattern: "user-color-143", premium: true },
      { pattern: "user-color-144", premium: true },
      { pattern: "user-color-145", premium: true },
      { pattern: "user-color-146", premium: true },
      { pattern: "user-color-147", premium: true },





    ],

    teamButtons: () => [
      { pattern: "team-color-1" },
      { pattern: "team-color-2" },
      { pattern: "team-color-3" },
      { pattern: "team-color-4" },
      { pattern: "team-color-5" },
      { pattern: "team-color-6" },
      { pattern: "team-color-7" },
      { pattern: "team-color-8" },
      { pattern: "team-color-9" },
      { pattern: "team-color-10" },

      { pattern: "team-color-18", premium: true },
      { pattern: "team-color-19", premium: true },
      { pattern: "team-color-20", premium: true },
      { pattern: "team-color-21", premium: true },
      { pattern: "team-color-22", premium: true },
      { pattern: "team-color-23", premium: true },
      { pattern: "team-color-24", premium: true },
      { pattern: "team-color-25", premium: true },
      { pattern: "team-color-26", premium: true },
      { pattern: "team-color-27", premium: true },
      { pattern: "team-color-28", premium: true },
      { pattern: "team-color-29", premium: true },
      { pattern: "team-color-30", premium: true },
      { pattern: "team-color-31", premium: true },
      { pattern: "team-color-32", premium: true },
      { pattern: "team-color-33", premium: true },
      { pattern: "team-color-34", premium: true },
      { pattern: "team-color-35", premium: true },
      { pattern: "team-color-36", premium: true },
      { pattern: "team-color-37", premium: true },
      { pattern: "team-color-38", premium: true },
      { pattern: "team-color-39", premium: true },
      { pattern: "team-color-40", premium: true },
      { pattern: "team-color-41", premium: true },
      { pattern: "team-color-42", premium: true },
      { pattern: "team-color-43", premium: true },
      { pattern: "team-color-44", premium: true },
      { pattern: "team-color-45", premium: true },
      { pattern: "team-color-46", premium: true },
      { pattern: "team-color-47", premium: true },
      { pattern: "team-color-48", premium: true },
      { pattern: "team-color-49", premium: true },
      { pattern: "team-color-50", premium: true },
      { pattern: "team-color-51", premium: true },
      { pattern: "team-color-52", premium: true },
      { pattern: "team-color-53", premium: true },
      { pattern: "team-color-54", premium: true },
      { pattern: "team-color-55", premium: true },
      { pattern: "team-color-56", premium: true },
      { pattern: "team-color-57", premium: true },
      { pattern: "team-color-58", premium: true },
      { pattern: "team-color-59", premium: true },
      { pattern: "team-color-60", premium: true },
      { pattern: "team-color-61", premium: true },
      { pattern: "team-color-62", premium: true },
      { pattern: "team-color-63", premium: true },
      { pattern: "team-color-64", premium: true },
      { pattern: "team-color-65", premium: true },
      { pattern: "team-color-66", premium: true },
      { pattern: "team-color-67", premium: true },
      { pattern: "team-color-68", premium: true },
      { pattern: "team-color-69", premium: true },
      { pattern: "team-color-70", premium: true },
      { pattern: "team-color-71", premium: true },
      { pattern: "team-color-72", premium: true },
      { pattern: "team-color-73", premium: true },
      { pattern: "team-color-74", premium: true },
      { pattern: "team-color-75", premium: true },
      { pattern: "team-color-76", premium: true },
      { pattern: "team-color-77", premium: true },
      { pattern: "team-color-78", premium: true },
      { pattern: "team-color-79", premium: true },
      { pattern: "team-color-80", premium: true },
      { pattern: "team-color-81", premium: true },
      { pattern: "team-color-82", premium: true },
      { pattern: "team-color-83", premium: true },
      { pattern: "team-color-84", premium: true },
      { pattern: "team-color-85", premium: true },
      { pattern: "team-color-86", premium: true },
      { pattern: "team-color-87", premium: true },
      { pattern: "team-color-88", premium: true },
      { pattern: "team-color-89", premium: true },
      { pattern: "team-color-90", premium: true },
      { pattern: "team-color-91", premium: true },
      { pattern: "team-color-92", premium: true },
      { pattern: "team-color-93", premium: true },
      { pattern: "team-color-94", premium: true },
      { pattern: "team-color-95", premium: true },
      { pattern: "team-color-96", premium: true },
      { pattern: "team-color-97", premium: true },
      { pattern: "team-color-98", premium: true },
      { pattern: "team-color-99", premium: true },
      { pattern: "team-color-100", premium: true },
      { pattern: "team-color-101", premium: true },
      { pattern: "team-color-102", premium: true },
      { pattern: "team-color-103", premium: true },
      { pattern: "team-color-104", premium: true },
      { pattern: "team-color-105", premium: true },
      { pattern: "team-color-106", premium: true },
      { pattern: "team-color-107", premium: true },
      { pattern: "team-color-108", premium: true },
      { pattern: "team-color-109", premium: true },
      { pattern: "team-color-110", premium: true },
      { pattern: "team-color-111", premium: true },
      { pattern: "team-color-112", premium: true },
      { pattern: "team-color-113", premium: true },
      { pattern: "team-color-114", premium: true },
      { pattern: "team-color-115", premium: true },
      { pattern: "team-color-116", premium: true },
      { pattern: "team-color-117", premium: true },
      { pattern: "team-color-118", premium: true },
      { pattern: "team-color-119", premium: true },
      { pattern: "team-color-120", premium: true },
      { pattern: "team-color-121", premium: true },
      { pattern: "team-color-122", premium: true },
      { pattern: "team-color-123", premium: true },
      { pattern: "team-color-124", premium: true },
      { pattern: "team-color-125", premium: true },
      { pattern: "team-color-126", premium: true },
      { pattern: "team-color-127", premium: true },
      { pattern: "team-color-128", premium: true },
      { pattern: "team-color-129", premium: true },
      { pattern: "team-color-130", premium: true },
      { pattern: "team-color-131", premium: true },
      { pattern: "team-color-132", premium: true },
      { pattern: "team-color-133", premium: true },
      { pattern: "team-color-134", premium: true },
      { pattern: "team-color-135", premium: true },
      { pattern: "team-color-136", premium: true },
      { pattern: "team-color-137", premium: true },
      { pattern: "team-color-138", premium: true },
      { pattern: "team-color-139", premium: true },
      { pattern: "team-color-140", premium: true },
      { pattern: "team-color-141", premium: true },
      { pattern: "team-color-142", premium: true },
      { pattern: "team-color-143", premium: true },
      { pattern: "team-color-144", premium: true },
      { pattern: "team-color-145", premium: true },
      { pattern: "team-color-146", premium: true },
      { pattern: "team-color-147", premium: true },





    ],

    clubButtons: () => [
      { pattern: "club-color-1" },
      { pattern: "club-color-2" },
      { pattern: "club-color-3" },
      { pattern: "club-color-4" },
      { pattern: "club-color-5" },
      { pattern: "club-color-6" },
      { pattern: "club-color-7" },
      { pattern: "club-color-8" },
      { pattern: "club-color-9" },
      { pattern: "club-color-10" },

      { pattern: "club-color-18", premium: true },
      { pattern: "club-color-19", premium: true },
      { pattern: "club-color-20", premium: true },
      { pattern: "club-color-21", premium: true },
      { pattern: "club-color-22", premium: true },
      { pattern: "club-color-23", premium: true },
      { pattern: "club-color-24", premium: true },
      { pattern: "club-color-25", premium: true },
      { pattern: "club-color-26", premium: true },
      { pattern: "club-color-27", premium: true },
      { pattern: "club-color-28", premium: true },
      { pattern: "club-color-29", premium: true },
      { pattern: "club-color-30", premium: true },
      { pattern: "club-color-31", premium: true },
      { pattern: "club-color-32", premium: true },
      { pattern: "club-color-33", premium: true },
      { pattern: "club-color-34", premium: true },
      { pattern: "club-color-35", premium: true },
      { pattern: "club-color-36", premium: true },
      { pattern: "club-color-37", premium: true },
      { pattern: "club-color-38", premium: true },
      { pattern: "club-color-39", premium: true },
      { pattern: "club-color-40", premium: true },
      { pattern: "club-color-41", premium: true },
      { pattern: "club-color-42", premium: true },
      { pattern: "club-color-43", premium: true },
      { pattern: "club-color-44", premium: true },
      { pattern: "club-color-45", premium: true },
      { pattern: "club-color-46", premium: true },
      { pattern: "club-color-47", premium: true },
      { pattern: "club-color-48", premium: true },
      { pattern: "club-color-49", premium: true },
      { pattern: "club-color-50", premium: true },
      { pattern: "club-color-51", premium: true },
      { pattern: "club-color-52", premium: true },
      { pattern: "club-color-53", premium: true },
      { pattern: "club-color-54", premium: true },
      { pattern: "club-color-55", premium: true },
      { pattern: "club-color-56", premium: true },
      { pattern: "club-color-57", premium: true },
      { pattern: "club-color-58", premium: true },
      { pattern: "club-color-59", premium: true },
      { pattern: "club-color-60", premium: true },
      { pattern: "club-color-61", premium: true },
      { pattern: "club-color-62", premium: true },
      { pattern: "club-color-63", premium: true },
      { pattern: "club-color-64", premium: true },
      { pattern: "club-color-65", premium: true },
      { pattern: "club-color-66", premium: true },
      { pattern: "club-color-67", premium: true },
      { pattern: "club-color-68", premium: true },
      { pattern: "club-color-69", premium: true },
      { pattern: "club-color-70", premium: true },
      { pattern: "club-color-71", premium: true },
      { pattern: "club-color-72", premium: true },
      { pattern: "club-color-73", premium: true },
      { pattern: "club-color-74", premium: true },
      { pattern: "club-color-75", premium: true },
      { pattern: "club-color-76", premium: true },
      { pattern: "club-color-77", premium: true },
      { pattern: "club-color-78", premium: true },
      { pattern: "club-color-79", premium: true },
      { pattern: "club-color-80", premium: true },
      { pattern: "club-color-81", premium: true },
      { pattern: "club-color-82", premium: true },
      { pattern: "club-color-83", premium: true },
      { pattern: "club-color-84", premium: true },
      { pattern: "club-color-85", premium: true },
      { pattern: "club-color-86", premium: true },
      { pattern: "club-color-87", premium: true },
      { pattern: "club-color-88", premium: true },
      { pattern: "club-color-89", premium: true },
      { pattern: "club-color-90", premium: true },
      { pattern: "club-color-91", premium: true },
      { pattern: "club-color-92", premium: true },
      { pattern: "club-color-93", premium: true },
      { pattern: "club-color-94", premium: true },
      { pattern: "club-color-95", premium: true },
      { pattern: "club-color-96", premium: true },
      { pattern: "club-color-97", premium: true },
      { pattern: "club-color-98", premium: true },
      { pattern: "club-color-99", premium: true },
      { pattern: "club-color-100", premium: true },
      { pattern: "club-color-101", premium: true },
      { pattern: "club-color-102", premium: true },
      { pattern: "club-color-103", premium: true },
      { pattern: "club-color-104", premium: true },
      { pattern: "club-color-105", premium: true },
      { pattern: "club-color-106", premium: true },
      { pattern: "club-color-107", premium: true },
      { pattern: "club-color-108", premium: true },
      { pattern: "club-color-109", premium: true },
      { pattern: "club-color-110", premium: true },
      { pattern: "club-color-111", premium: true },
      { pattern: "club-color-112", premium: true },
      { pattern: "club-color-113", premium: true },
      { pattern: "club-color-114", premium: true },
      { pattern: "club-color-115", premium: true },
      { pattern: "club-color-116", premium: true },
      { pattern: "club-color-117", premium: true },
      { pattern: "club-color-118", premium: true },
      { pattern: "club-color-119", premium: true },
      { pattern: "club-color-120", premium: true },
      { pattern: "club-color-121", premium: true },
      { pattern: "club-color-122", premium: true },
      { pattern: "club-color-123", premium: true },
      { pattern: "club-color-124", premium: true },
      { pattern: "club-color-125", premium: true },
      { pattern: "club-color-126", premium: true },
      { pattern: "club-color-127", premium: true },
      { pattern: "club-color-128", premium: true },
      { pattern: "club-color-129", premium: true },
      { pattern: "club-color-130", premium: true },
      { pattern: "club-color-131", premium: true },
      { pattern: "club-color-132", premium: true },
      { pattern: "club-color-133", premium: true },
      { pattern: "club-color-134", premium: true },
      { pattern: "club-color-135", premium: true },
      { pattern: "club-color-136", premium: true },
      { pattern: "club-color-137", premium: true },
      { pattern: "club-color-138", premium: true },
      { pattern: "club-color-139", premium: true },
      { pattern: "club-color-140", premium: true },
      { pattern: "club-color-141", premium: true },
      { pattern: "club-color-142", premium: true },
      { pattern: "club-color-143", premium: true },
      { pattern: "club-color-144", premium: true },
      { pattern: "club-color-145", premium: true },
      { pattern: "club-color-146", premium: true },
      { pattern: "club-color-147", premium: true },





    ],

    userBackgrounds: () => [
      { pattern: "user-bg-color-1" },
      { pattern: "user-bg-color-2" },
      { pattern: "user-bg-color-3" },
      { pattern: "user-bg-color-4" },
      { pattern: "user-bg-color-5" },
      { pattern: "user-bg-color-6" },
      { pattern: "user-bg-color-7" },
      { pattern: "user-bg-color-8" },
      { pattern: "user-bg-color-9" },
      { pattern: "user-bg-color-10" },

      { pattern: "user-bg-color-161", premium: true, picture: true },
      { pattern: "user-bg-color-162", premium: true, picture: true },
      { pattern: "user-bg-color-163", premium: true, picture: true },


      { pattern: "user-bg-color-164", premium: true, picture: true },
      { pattern: "user-bg-color-165", premium: true, picture: true },
      { pattern: "user-bg-color-166", premium: true, picture: true },
      { pattern: "user-bg-color-167", premium: true, picture: true },
      { pattern: "user-bg-color-168", premium: true, picture: true },
      { pattern: "user-bg-color-169", premium: true, picture: true },
      { pattern: "user-bg-color-170", premium: true, picture: true },
      { pattern: "user-bg-color-171", premium: true, picture: true },
      { pattern: "user-bg-color-172", premium: true, picture: true },
      { pattern: "user-bg-color-173", premium: true, picture: true },
      { pattern: "user-bg-color-174", premium: true, picture: true },
      { pattern: "user-bg-color-175", premium: true, picture: true },
      { pattern: "user-bg-color-176", premium: true, picture: true },
      { pattern: "user-bg-color-177", premium: true, picture: true },
      { pattern: "user-bg-color-178", premium: true, picture: true },
      { pattern: "user-bg-color-179", premium: true, picture: true },
      { pattern: "user-bg-color-180", premium: true, picture: true },
      { pattern: "user-bg-color-181", premium: true, picture: true },
      { pattern: "user-bg-color-182", premium: true, picture: true },
      { pattern: "user-bg-color-183", premium: true, picture: true },
      { pattern: "user-bg-color-184", premium: true, picture: true },
      { pattern: "user-bg-color-185", premium: true, picture: true },
      { pattern: "user-bg-color-186", premium: true, picture: true },
      { pattern: "user-bg-color-187", premium: true, picture: true },
      { pattern: "user-bg-color-188", premium: true, picture: true },
      { pattern: "user-bg-color-189", premium: true, picture: true },
      { pattern: "user-bg-color-190", premium: true, picture: true },
      { pattern: "user-bg-color-191", premium: true, picture: true },
      { pattern: "user-bg-color-192", premium: true, picture: true },
      { pattern: "user-bg-color-193", premium: true, picture: true },
      { pattern: "user-bg-color-194", premium: true, picture: true },
      { pattern: "user-bg-color-195", premium: true, picture: true },
      { pattern: "user-bg-color-196", premium: true, picture: true },
      { pattern: "user-bg-color-197", premium: true, picture: true },
      { pattern: "user-bg-color-198", premium: true, picture: true },
      { pattern: "user-bg-color-199", premium: true, picture: true },
      { pattern: "user-bg-color-200", premium: true, picture: true },
      { pattern: "user-bg-color-201", premium: true, picture: true },

      { pattern: "user-bg-color-11", premium: true, picture: true },
      { pattern: "user-bg-color-12", premium: true, picture: true },
      { pattern: "user-bg-color-13", premium: true, picture: true },
      { pattern: "user-bg-color-14", premium: true, picture: true },
      { pattern: "user-bg-color-15", premium: true, picture: true },
      { pattern: "user-bg-color-16", premium: true, picture: true },
      { pattern: "user-bg-color-17", premium: true, picture: true },

      { pattern: "user-bg-color-18", premium: true },
      { pattern: "user-bg-color-19", premium: true },
      { pattern: "user-bg-color-20", premium: true },
      { pattern: "user-bg-color-21", premium: true },
      { pattern: "user-bg-color-22", premium: true },
      { pattern: "user-bg-color-23", premium: true },
      { pattern: "user-bg-color-24", premium: true },
      { pattern: "user-bg-color-25", premium: true },
      { pattern: "user-bg-color-26", premium: true },
      { pattern: "user-bg-color-27", premium: true },
      { pattern: "user-bg-color-28", premium: true },
      { pattern: "user-bg-color-29", premium: true },
      { pattern: "user-bg-color-30", premium: true },
      { pattern: "user-bg-color-31", premium: true },
      { pattern: "user-bg-color-32", premium: true },
      { pattern: "user-bg-color-33", premium: true },
      { pattern: "user-bg-color-34", premium: true },
      { pattern: "user-bg-color-35", premium: true },
      { pattern: "user-bg-color-36", premium: true },
      { pattern: "user-bg-color-37", premium: true },
      { pattern: "user-bg-color-38", premium: true },
      { pattern: "user-bg-color-39", premium: true },
      { pattern: "user-bg-color-40", premium: true },
      { pattern: "user-bg-color-41", premium: true },
      { pattern: "user-bg-color-42", premium: true },
      { pattern: "user-bg-color-43", premium: true },
      { pattern: "user-bg-color-44", premium: true },
      { pattern: "user-bg-color-45", premium: true },
      { pattern: "user-bg-color-46", premium: true },
      { pattern: "user-bg-color-47", premium: true },
      { pattern: "user-bg-color-48", premium: true },
      { pattern: "user-bg-color-49", premium: true },
      { pattern: "user-bg-color-50", premium: true },
      { pattern: "user-bg-color-51", premium: true },
      { pattern: "user-bg-color-52", premium: true },
      { pattern: "user-bg-color-53", premium: true },
      { pattern: "user-bg-color-54", premium: true },
      { pattern: "user-bg-color-55", premium: true },
      { pattern: "user-bg-color-56", premium: true },
      { pattern: "user-bg-color-57", premium: true },
      { pattern: "user-bg-color-58", premium: true },
      { pattern: "user-bg-color-59", premium: true },
      { pattern: "user-bg-color-60", premium: true },
      { pattern: "user-bg-color-61", premium: true },
      { pattern: "user-bg-color-62", premium: true },
      { pattern: "user-bg-color-63", premium: true },
      { pattern: "user-bg-color-64", premium: true },
      { pattern: "user-bg-color-65", premium: true },
      { pattern: "user-bg-color-66", premium: true },
      { pattern: "user-bg-color-67", premium: true },
      { pattern: "user-bg-color-68", premium: true },
      { pattern: "user-bg-color-69", premium: true },
      { pattern: "user-bg-color-70", premium: true },
      { pattern: "user-bg-color-71", premium: true },
      { pattern: "user-bg-color-72", premium: true },
      { pattern: "user-bg-color-73", premium: true },
      { pattern: "user-bg-color-74", premium: true },
      { pattern: "user-bg-color-75", premium: true },
      { pattern: "user-bg-color-76", premium: true },
      { pattern: "user-bg-color-77", premium: true },
      { pattern: "user-bg-color-78", premium: true },
      { pattern: "user-bg-color-79", premium: true },
      { pattern: "user-bg-color-80", premium: true },
      { pattern: "user-bg-color-81", premium: true },
      { pattern: "user-bg-color-82", premium: true },
      { pattern: "user-bg-color-83", premium: true },
      { pattern: "user-bg-color-84", premium: true },
      { pattern: "user-bg-color-85", premium: true },
      { pattern: "user-bg-color-86", premium: true },
      { pattern: "user-bg-color-87", premium: true },
      { pattern: "user-bg-color-88", premium: true },
      { pattern: "user-bg-color-89", premium: true },
      { pattern: "user-bg-color-90", premium: true },
      { pattern: "user-bg-color-91", premium: true },
      { pattern: "user-bg-color-92", premium: true },
      { pattern: "user-bg-color-93", premium: true },
      { pattern: "user-bg-color-94", premium: true },
      { pattern: "user-bg-color-95", premium: true },
      { pattern: "user-bg-color-96", premium: true },
      { pattern: "user-bg-color-97", premium: true },
      { pattern: "user-bg-color-98", premium: true },
      { pattern: "user-bg-color-99", premium: true },
      { pattern: "user-bg-color-100", premium: true },
      { pattern: "user-bg-color-101", premium: true },
      { pattern: "user-bg-color-102", premium: true },
      { pattern: "user-bg-color-103", premium: true },
      { pattern: "user-bg-color-104", premium: true },
      { pattern: "user-bg-color-105", premium: true },
      { pattern: "user-bg-color-106", premium: true },
      { pattern: "user-bg-color-107", premium: true },
      { pattern: "user-bg-color-108", premium: true },
      { pattern: "user-bg-color-109", premium: true },
      { pattern: "user-bg-color-110", premium: true },
      { pattern: "user-bg-color-111", premium: true },
      { pattern: "user-bg-color-112", premium: true },
      { pattern: "user-bg-color-113", premium: true },
      { pattern: "user-bg-color-114", premium: true },
      { pattern: "user-bg-color-115", premium: true },
      { pattern: "user-bg-color-116", premium: true },
      { pattern: "user-bg-color-117", premium: true },
      { pattern: "user-bg-color-118", premium: true },
      { pattern: "user-bg-color-119", premium: true },
      { pattern: "user-bg-color-120", premium: true },
      { pattern: "user-bg-color-121", premium: true },
      { pattern: "user-bg-color-122", premium: true },
      { pattern: "user-bg-color-123", premium: true },
      { pattern: "user-bg-color-124", premium: true },
      { pattern: "user-bg-color-125", premium: true },
      { pattern: "user-bg-color-126", premium: true },
      { pattern: "user-bg-color-127", premium: true },
      { pattern: "user-bg-color-128", premium: true },
      { pattern: "user-bg-color-129", premium: true },
      { pattern: "user-bg-color-130", premium: true },
      { pattern: "user-bg-color-131", premium: true },
      { pattern: "user-bg-color-132", premium: true },
      { pattern: "user-bg-color-133", premium: true },
      { pattern: "user-bg-color-134", premium: true },
      { pattern: "user-bg-color-135", premium: true },
      { pattern: "user-bg-color-136", premium: true },
      { pattern: "user-bg-color-137", premium: true },
      { pattern: "user-bg-color-138", premium: true },
      { pattern: "user-bg-color-139", premium: true },
      { pattern: "user-bg-color-140", premium: true },
      { pattern: "user-bg-color-141", premium: true },
      { pattern: "user-bg-color-142", premium: true },
      { pattern: "user-bg-color-143", premium: true },
      { pattern: "user-bg-color-144", premium: true },
      { pattern: "user-bg-color-145", premium: true },
      { pattern: "user-bg-color-146", premium: true },
      { pattern: "user-bg-color-147", premium: true },


    ],
   teamBackgrounds: () => [

      { pattern: "team-bg-color-1" },
      { pattern: "team-bg-color-2" },
      { pattern: "team-bg-color-3" },
      { pattern: "team-bg-color-4" },
      { pattern: "team-bg-color-5" },
      { pattern: "team-bg-color-6" },
      { pattern: "team-bg-color-7" },
      { pattern: "team-bg-color-8" },
      { pattern: "team-bg-color-9" },
      { pattern: "team-bg-color-10" },

      { pattern: "team-bg-color-161", premium: true, picture: true },
      { pattern: "team-bg-color-162", premium: true, picture: true },
      { pattern: "team-bg-color-163", premium: true, picture: true },

      { pattern: "team-bg-color-164", premium: true, picture: true },
      { pattern: "team-bg-color-165", premium: true, picture: true },
      { pattern: "team-bg-color-166", premium: true, picture: true },
      { pattern: "team-bg-color-167", premium: true, picture: true },
      { pattern: "team-bg-color-168", premium: true, picture: true },
      { pattern: "team-bg-color-169", premium: true, picture: true },
      { pattern: "team-bg-color-170", premium: true, picture: true },
      { pattern: "team-bg-color-171", premium: true, picture: true },
      { pattern: "team-bg-color-172", premium: true, picture: true },
      { pattern: "team-bg-color-173", premium: true, picture: true },
      { pattern: "team-bg-color-174", premium: true, picture: true },
      { pattern: "team-bg-color-175", premium: true, picture: true },
      { pattern: "team-bg-color-176", premium: true, picture: true },
      { pattern: "team-bg-color-177", premium: true, picture: true },
      { pattern: "team-bg-color-178", premium: true, picture: true },
      { pattern: "team-bg-color-179", premium: true, picture: true },
      { pattern: "team-bg-color-180", premium: true, picture: true },
      { pattern: "team-bg-color-181", premium: true, picture: true },
      { pattern: "team-bg-color-182", premium: true, picture: true },
      { pattern: "team-bg-color-183", premium: true, picture: true },
      { pattern: "team-bg-color-184", premium: true, picture: true },
      { pattern: "team-bg-color-185", premium: true, picture: true },
      { pattern: "team-bg-color-186", premium: true, picture: true },
      { pattern: "team-bg-color-187", premium: true, picture: true },
      { pattern: "team-bg-color-188", premium: true, picture: true },
      { pattern: "team-bg-color-189", premium: true, picture: true },
      { pattern: "team-bg-color-190", premium: true, picture: true },
      { pattern: "team-bg-color-191", premium: true, picture: true },
      { pattern: "team-bg-color-192", premium: true, picture: true },
      { pattern: "team-bg-color-193", premium: true, picture: true },
      { pattern: "team-bg-color-194", premium: true, picture: true },
      { pattern: "team-bg-color-195", premium: true, picture: true },
      { pattern: "team-bg-color-196", premium: true, picture: true },
      { pattern: "team-bg-color-197", premium: true, picture: true },
      { pattern: "team-bg-color-198", premium: true, picture: true },
      { pattern: "team-bg-color-199", premium: true, picture: true },
      { pattern: "team-bg-color-200", premium: true, picture: true },
      { pattern: "team-bg-color-201", premium: true, picture: true },

      { pattern: "team-bg-color-11", premium: true, picture: true },
      { pattern: "team-bg-color-12", premium: true, picture: true },
      { pattern: "team-bg-color-13", premium: true, picture: true },
      { pattern: "team-bg-color-14", premium: true, picture: true },
      { pattern: "team-bg-color-15", premium: true, picture: true },
      { pattern: "team-bg-color-16", premium: true, picture: true },
      { pattern: "team-bg-color-17", premium: true, picture: true },

      { pattern: "team-bg-color-18", premium: true },
      { pattern: "team-bg-color-19", premium: true },
      { pattern: "team-bg-color-20", premium: true },
      { pattern: "team-bg-color-21", premium: true },
      { pattern: "team-bg-color-22", premium: true },
      { pattern: "team-bg-color-23", premium: true },
      { pattern: "team-bg-color-24", premium: true },
      { pattern: "team-bg-color-25", premium: true },
      { pattern: "team-bg-color-26", premium: true },
      { pattern: "team-bg-color-27", premium: true },
      { pattern: "team-bg-color-28", premium: true },
      { pattern: "team-bg-color-29", premium: true },
      { pattern: "team-bg-color-30", premium: true },
      { pattern: "team-bg-color-31", premium: true },
      { pattern: "team-bg-color-32", premium: true },
      { pattern: "team-bg-color-33", premium: true },
      { pattern: "team-bg-color-34", premium: true },
      { pattern: "team-bg-color-35", premium: true },
      { pattern: "team-bg-color-36", premium: true },
      { pattern: "team-bg-color-37", premium: true },
      { pattern: "team-bg-color-38", premium: true },
      { pattern: "team-bg-color-39", premium: true },
      { pattern: "team-bg-color-40", premium: true },
      { pattern: "team-bg-color-41", premium: true },
      { pattern: "team-bg-color-42", premium: true },
      { pattern: "team-bg-color-43", premium: true },
      { pattern: "team-bg-color-44", premium: true },
      { pattern: "team-bg-color-45", premium: true },
      { pattern: "team-bg-color-46", premium: true },
      { pattern: "team-bg-color-47", premium: true },
      { pattern: "team-bg-color-48", premium: true },
      { pattern: "team-bg-color-49", premium: true },
      { pattern: "team-bg-color-50", premium: true },
      { pattern: "team-bg-color-51", premium: true },
      { pattern: "team-bg-color-52", premium: true },
      { pattern: "team-bg-color-53", premium: true },
      { pattern: "team-bg-color-54", premium: true },
      { pattern: "team-bg-color-55", premium: true },
      { pattern: "team-bg-color-56", premium: true },
      { pattern: "team-bg-color-57", premium: true },
      { pattern: "team-bg-color-58", premium: true },
      { pattern: "team-bg-color-59", premium: true },
      { pattern: "team-bg-color-60", premium: true },
      { pattern: "team-bg-color-61", premium: true },
      { pattern: "team-bg-color-62", premium: true },
      { pattern: "team-bg-color-63", premium: true },
      { pattern: "team-bg-color-64", premium: true },
      { pattern: "team-bg-color-65", premium: true },
      { pattern: "team-bg-color-66", premium: true },
      { pattern: "team-bg-color-67", premium: true },
      { pattern: "team-bg-color-68", premium: true },
      { pattern: "team-bg-color-69", premium: true },
      { pattern: "team-bg-color-70", premium: true },
      { pattern: "team-bg-color-71", premium: true },
      { pattern: "team-bg-color-72", premium: true },
      { pattern: "team-bg-color-73", premium: true },
      { pattern: "team-bg-color-74", premium: true },
      { pattern: "team-bg-color-75", premium: true },
      { pattern: "team-bg-color-76", premium: true },
      { pattern: "team-bg-color-77", premium: true },
      { pattern: "team-bg-color-78", premium: true },
      { pattern: "team-bg-color-79", premium: true },
      { pattern: "team-bg-color-80", premium: true },
      { pattern: "team-bg-color-81", premium: true },
      { pattern: "team-bg-color-82", premium: true },
      { pattern: "team-bg-color-83", premium: true },
      { pattern: "team-bg-color-84", premium: true },
      { pattern: "team-bg-color-85", premium: true },
      { pattern: "team-bg-color-86", premium: true },
      { pattern: "team-bg-color-87", premium: true },
      { pattern: "team-bg-color-88", premium: true },
      { pattern: "team-bg-color-89", premium: true },
      { pattern: "team-bg-color-90", premium: true },
      { pattern: "team-bg-color-91", premium: true },
      { pattern: "team-bg-color-92", premium: true },
      { pattern: "team-bg-color-93", premium: true },
      { pattern: "team-bg-color-94", premium: true },
      { pattern: "team-bg-color-95", premium: true },
      { pattern: "team-bg-color-96", premium: true },
      { pattern: "team-bg-color-97", premium: true },
      { pattern: "team-bg-color-98", premium: true },
      { pattern: "team-bg-color-99", premium: true },
      { pattern: "team-bg-color-100", premium: true },
      { pattern: "team-bg-color-101", premium: true },
      { pattern: "team-bg-color-102", premium: true },
      { pattern: "team-bg-color-103", premium: true },
      { pattern: "team-bg-color-104", premium: true },
      { pattern: "team-bg-color-105", premium: true },
      { pattern: "team-bg-color-106", premium: true },
      { pattern: "team-bg-color-107", premium: true },
      { pattern: "team-bg-color-108", premium: true },
      { pattern: "team-bg-color-109", premium: true },
      { pattern: "team-bg-color-110", premium: true },
      { pattern: "team-bg-color-111", premium: true },
      { pattern: "team-bg-color-112", premium: true },
      { pattern: "team-bg-color-113", premium: true },
      { pattern: "team-bg-color-114", premium: true },
      { pattern: "team-bg-color-115", premium: true },
      { pattern: "team-bg-color-116", premium: true },
      { pattern: "team-bg-color-117", premium: true },
      { pattern: "team-bg-color-118", premium: true },
      { pattern: "team-bg-color-119", premium: true },
      { pattern: "team-bg-color-120", premium: true },
      { pattern: "team-bg-color-121", premium: true },
      { pattern: "team-bg-color-122", premium: true },
      { pattern: "team-bg-color-123", premium: true },
      { pattern: "team-bg-color-124", premium: true },
      { pattern: "team-bg-color-125", premium: true },
      { pattern: "team-bg-color-126", premium: true },
      { pattern: "team-bg-color-127", premium: true },
      { pattern: "team-bg-color-128", premium: true },
      { pattern: "team-bg-color-129", premium: true },
      { pattern: "team-bg-color-130", premium: true },
      { pattern: "team-bg-color-131", premium: true },
      { pattern: "team-bg-color-132", premium: true },
      { pattern: "team-bg-color-133", premium: true },
      { pattern: "team-bg-color-134", premium: true },
      { pattern: "team-bg-color-135", premium: true },
      { pattern: "team-bg-color-136", premium: true },
      { pattern: "team-bg-color-137", premium: true },
      { pattern: "team-bg-color-138", premium: true },
      { pattern: "team-bg-color-139", premium: true },
      { pattern: "team-bg-color-140", premium: true },
      { pattern: "team-bg-color-141", premium: true },
      { pattern: "team-bg-color-142", premium: true },
      { pattern: "team-bg-color-143", premium: true },
      { pattern: "team-bg-color-144", premium: true },
      { pattern: "team-bg-color-145", premium: true },
      { pattern: "team-bg-color-146", premium: true },
      { pattern: "team-bg-color-147", premium: true },

    ],
    clubBackgrounds: () => [

      { pattern: "club-bg-color-1" },
      { pattern: "club-bg-color-2" },
      { pattern: "club-bg-color-3" },
      { pattern: "club-bg-color-4" },
      { pattern: "club-bg-color-5" },
      { pattern: "club-bg-color-6" },
      { pattern: "club-bg-color-7" },
      { pattern: "club-bg-color-8" },
      { pattern: "club-bg-color-9" },
      { pattern: "club-bg-color-10" },

      { pattern: "club-bg-color-161", premium: true, picture: true },
      { pattern: "club-bg-color-162", premium: true, picture: true },
      { pattern: "club-bg-color-163", premium: true, picture: true },
      { pattern: "club-bg-color-164", premium: true, picture: true },
      { pattern: "club-bg-color-165", premium: true, picture: true },
      { pattern: "club-bg-color-166", premium: true, picture: true },
      { pattern: "club-bg-color-167", premium: true, picture: true },
      { pattern: "club-bg-color-168", premium: true, picture: true },
      { pattern: "club-bg-color-169", premium: true, picture: true },
      { pattern: "club-bg-color-170", premium: true, picture: true },
      { pattern: "club-bg-color-171", premium: true, picture: true },
      { pattern: "club-bg-color-172", premium: true, picture: true },
      { pattern: "club-bg-color-173", premium: true, picture: true },
      { pattern: "club-bg-color-174", premium: true, picture: true },
      { pattern: "club-bg-color-175", premium: true, picture: true },
      { pattern: "club-bg-color-176", premium: true, picture: true },
      { pattern: "club-bg-color-177", premium: true, picture: true },
      { pattern: "club-bg-color-178", premium: true, picture: true },
      { pattern: "club-bg-color-179", premium: true, picture: true },
      { pattern: "club-bg-color-180", premium: true, picture: true },
      { pattern: "club-bg-color-181", premium: true, picture: true },
      { pattern: "club-bg-color-182", premium: true, picture: true },
      { pattern: "club-bg-color-183", premium: true, picture: true },
      { pattern: "club-bg-color-184", premium: true, picture: true },
      { pattern: "club-bg-color-185", premium: true, picture: true },
      { pattern: "club-bg-color-186", premium: true, picture: true },
      { pattern: "club-bg-color-187", premium: true, picture: true },
      { pattern: "club-bg-color-188", premium: true, picture: true },
      { pattern: "club-bg-color-189", premium: true, picture: true },
      { pattern: "club-bg-color-190", premium: true, picture: true },
      { pattern: "club-bg-color-191", premium: true, picture: true },
      { pattern: "club-bg-color-192", premium: true, picture: true },
      { pattern: "club-bg-color-193", premium: true, picture: true },
      { pattern: "club-bg-color-194", premium: true, picture: true },
      { pattern: "club-bg-color-195", premium: true, picture: true },
      { pattern: "club-bg-color-196", premium: true, picture: true },
      { pattern: "club-bg-color-197", premium: true, picture: true },
      { pattern: "club-bg-color-198", premium: true, picture: true },
      { pattern: "club-bg-color-199", premium: true, picture: true },
      { pattern: "club-bg-color-200", premium: true, picture: true },
      { pattern: "club-bg-color-201", premium: true, picture: true },

      { pattern: "club-bg-color-11", premium: true, picture: true },
      { pattern: "club-bg-color-12", premium: true, picture: true },
      { pattern: "club-bg-color-13", premium: true, picture: true },
      { pattern: "club-bg-color-14", premium: true, picture: true },
      { pattern: "club-bg-color-15", premium: true, picture: true },
      { pattern: "club-bg-color-16", premium: true, picture: true },
      { pattern: "club-bg-color-17", premium: true, picture: true },

      { pattern: "club-bg-color-18", premium: true },
      { pattern: "club-bg-color-19", premium: true },
      { pattern: "club-bg-color-20", premium: true },
      { pattern: "club-bg-color-21", premium: true },
      { pattern: "club-bg-color-22", premium: true },
      { pattern: "club-bg-color-23", premium: true },
      { pattern: "club-bg-color-24", premium: true },
      { pattern: "club-bg-color-25", premium: true },
      { pattern: "club-bg-color-26", premium: true },
      { pattern: "club-bg-color-27", premium: true },
      { pattern: "club-bg-color-28", premium: true },
      { pattern: "club-bg-color-29", premium: true },
      { pattern: "club-bg-color-30", premium: true },
      { pattern: "club-bg-color-31", premium: true },
      { pattern: "club-bg-color-32", premium: true },
      { pattern: "club-bg-color-33", premium: true },
      { pattern: "club-bg-color-34", premium: true },
      { pattern: "club-bg-color-35", premium: true },
      { pattern: "club-bg-color-36", premium: true },
      { pattern: "club-bg-color-37", premium: true },
      { pattern: "club-bg-color-38", premium: true },
      { pattern: "club-bg-color-39", premium: true },
      { pattern: "club-bg-color-40", premium: true },
      { pattern: "club-bg-color-41", premium: true },
      { pattern: "club-bg-color-42", premium: true },
      { pattern: "club-bg-color-43", premium: true },
      { pattern: "club-bg-color-44", premium: true },
      { pattern: "club-bg-color-45", premium: true },
      { pattern: "club-bg-color-46", premium: true },
      { pattern: "club-bg-color-47", premium: true },
      { pattern: "club-bg-color-48", premium: true },
      { pattern: "club-bg-color-49", premium: true },
      { pattern: "club-bg-color-50", premium: true },
      { pattern: "club-bg-color-51", premium: true },
      { pattern: "club-bg-color-52", premium: true },
      { pattern: "club-bg-color-53", premium: true },
      { pattern: "club-bg-color-54", premium: true },
      { pattern: "club-bg-color-55", premium: true },
      { pattern: "club-bg-color-56", premium: true },
      { pattern: "club-bg-color-57", premium: true },
      { pattern: "club-bg-color-58", premium: true },
      { pattern: "club-bg-color-59", premium: true },
      { pattern: "club-bg-color-60", premium: true },
      { pattern: "club-bg-color-61", premium: true },
      { pattern: "club-bg-color-62", premium: true },
      { pattern: "club-bg-color-63", premium: true },
      { pattern: "club-bg-color-64", premium: true },
      { pattern: "club-bg-color-65", premium: true },
      { pattern: "club-bg-color-66", premium: true },
      { pattern: "club-bg-color-67", premium: true },
      { pattern: "club-bg-color-68", premium: true },
      { pattern: "club-bg-color-69", premium: true },
      { pattern: "club-bg-color-70", premium: true },
      { pattern: "club-bg-color-71", premium: true },
      { pattern: "club-bg-color-72", premium: true },
      { pattern: "club-bg-color-73", premium: true },
      { pattern: "club-bg-color-74", premium: true },
      { pattern: "club-bg-color-75", premium: true },
      { pattern: "club-bg-color-76", premium: true },
      { pattern: "club-bg-color-77", premium: true },
      { pattern: "club-bg-color-78", premium: true },
      { pattern: "club-bg-color-79", premium: true },
      { pattern: "club-bg-color-80", premium: true },
      { pattern: "club-bg-color-81", premium: true },
      { pattern: "club-bg-color-82", premium: true },
      { pattern: "club-bg-color-83", premium: true },
      { pattern: "club-bg-color-84", premium: true },
      { pattern: "club-bg-color-85", premium: true },
      { pattern: "club-bg-color-86", premium: true },
      { pattern: "club-bg-color-87", premium: true },
      { pattern: "club-bg-color-88", premium: true },
      { pattern: "club-bg-color-89", premium: true },
      { pattern: "club-bg-color-90", premium: true },
      { pattern: "club-bg-color-91", premium: true },
      { pattern: "club-bg-color-92", premium: true },
      { pattern: "club-bg-color-93", premium: true },
      { pattern: "club-bg-color-94", premium: true },
      { pattern: "club-bg-color-95", premium: true },
      { pattern: "club-bg-color-96", premium: true },
      { pattern: "club-bg-color-97", premium: true },
      { pattern: "club-bg-color-98", premium: true },
      { pattern: "club-bg-color-99", premium: true },
      { pattern: "club-bg-color-100", premium: true },
      { pattern: "club-bg-color-101", premium: true },
      { pattern: "club-bg-color-102", premium: true },
      { pattern: "club-bg-color-103", premium: true },
      { pattern: "club-bg-color-104", premium: true },
      { pattern: "club-bg-color-105", premium: true },
      { pattern: "club-bg-color-106", premium: true },
      { pattern: "club-bg-color-107", premium: true },
      { pattern: "club-bg-color-108", premium: true },
      { pattern: "club-bg-color-109", premium: true },
      { pattern: "club-bg-color-110", premium: true },
      { pattern: "club-bg-color-111", premium: true },
      { pattern: "club-bg-color-112", premium: true },
      { pattern: "club-bg-color-113", premium: true },
      { pattern: "club-bg-color-114", premium: true },
      { pattern: "club-bg-color-115", premium: true },
      { pattern: "club-bg-color-116", premium: true },
      { pattern: "club-bg-color-117", premium: true },
      { pattern: "club-bg-color-118", premium: true },
      { pattern: "club-bg-color-119", premium: true },
      { pattern: "club-bg-color-120", premium: true },
      { pattern: "club-bg-color-121", premium: true },
      { pattern: "club-bg-color-122", premium: true },
      { pattern: "club-bg-color-123", premium: true },
      { pattern: "club-bg-color-124", premium: true },
      { pattern: "club-bg-color-125", premium: true },
      { pattern: "club-bg-color-126", premium: true },
      { pattern: "club-bg-color-127", premium: true },
      { pattern: "club-bg-color-128", premium: true },
      { pattern: "club-bg-color-129", premium: true },
      { pattern: "club-bg-color-130", premium: true },
      { pattern: "club-bg-color-131", premium: true },
      { pattern: "club-bg-color-132", premium: true },
      { pattern: "club-bg-color-133", premium: true },
      { pattern: "club-bg-color-134", premium: true },
      { pattern: "club-bg-color-135", premium: true },
      { pattern: "club-bg-color-136", premium: true },
      { pattern: "club-bg-color-137", premium: true },
      { pattern: "club-bg-color-138", premium: true },
      { pattern: "club-bg-color-139", premium: true },
      { pattern: "club-bg-color-140", premium: true },
      { pattern: "club-bg-color-141", premium: true },
      { pattern: "club-bg-color-142", premium: true },
      { pattern: "club-bg-color-143", premium: true },
      { pattern: "club-bg-color-144", premium: true },
      { pattern: "club-bg-color-145", premium: true },
      { pattern: "club-bg-color-146", premium: true },
      { pattern: "club-bg-color-147", premium: true },


    ],

  },

  props: {
    title: {
      type: String,
      default: () => "",
    },
    premiumTitle: {
      type: String,
      default: () => "",
    },

    palete: {
      type: String,
      default: () => "",
    },

    value: {
      type: String,
      default: () => "",
    },

    setter: {
      type: Function,
      default: () => "",
    },
  },

  methods:{
      doSetter(pal){
          if(+pal?.premium && !this.premiumAccess){
              this.$store.dispatch('toggleBottomMenu', {menu: 'getpremiumbm', visible: true})
              return

          }

          this.setter(pal)
          
      },
      doSpoiler1(){
          if(!this.premiumAccess){
              this.$store.dispatch('toggleBottomMenu', {menu: 'getpremiumbm', visible: true})
              return

          }

          this.spoilered1 = true;
      },

      doHideSpoiler1(){
          this.spoilered1 = false;

      },

      doSpoiler2(){
          if(!this.premiumAccess){
              this.$store.dispatch('toggleBottomMenu', {menu: 'getpremiumbm', visible: true})
              return

          }

          this.spoilered2 = true;
      },

      doHideSpoiler2(){
          this.spoilered2 = false;

      }

  },



};
</script>

<style lang="scss" scoped>

.is-premium{
  filter: blur(12px);
}

.rainbow {


    &.r-other{
        margin-top: 15px;
    }

    &.short{
        max-height: 120px;
        @media (max-width: 399px){
          max-height: 112px;

        }
        overflow: hidden;
    }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 5px 15px;

  @media (max-width: 399px) {
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.theme {
  text-align: center;
  margin: 10px 0;

  
  .premium-title{
    margin-top: 28px;
  }
  .premium-title,
  .title {
    margin-top: 3px;
    font-family: "Montserrat";

    &.premium-title::before{
        margin-right: 5px;
        content: "\f521";
        font-family: "Font Awesome 5 Free";
        box-sizing: content-box;
        color: gold;
        text-shadow: 1px 1px 3px black;
    }

    font-weight: 500;
    @media (max-height: 649px) {
      font-size: 100%;
    }
    font-size: 120%;

    @media (min-height: 700px) and (min-width: 400px) {
      font-size: 130%;
    }
  }

  .color {


    @media (max-width: 399px) {
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        height: 20px;
        width: 20px;
    }


    
    display: inline-block;
    // width: 48px;
    // width: auto;
    height: 32px;
    margin: 4px;
    box-shadow: 2px 2px 2px black;

    &:hover {
      box-shadow: 2px 2px 3px black;
      cursor: pointer;
    }

    &.active {

      outline: 3px solid #fff;
      transform: scale(1.12);
      transition: all .2s ease;
    }
  }

    .delim{
        height: 13px;
    }

}
</style>