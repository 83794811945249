import { WSS_URL} from '@/assets/js/config'
import {showSplasher, internalLocHref, hideSplasher} from "@/assets/js/utils";

const RECONNECT_TIMEOUT = 3000
const ALIVE_INTERVAL = 4 * 60000

let wsConnection = null

function initSocketClient(vue, user_id){

    let alive = null

    if(!user_id){
        return
    }
    wsConnection = new WebSocket(WSS_URL);
    wsConnection.onopen = function() {
        console.log("wss connected");
    };
    
    const token = localStorage.getItem('xcheertoken').split('').splice(0, 64).reverse().join('')
    
    wsConnection.onmessage = async (m) => {

        if(m.data === 'way'){
            wsConnection.send(
                JSON.stringify({
                    type: 'wayr',
                    user_id,
                    token
                })
            )

            return
        } 

        try {
            const pkt = JSON.parse(m.data)

            if(pkt.type==='joined'){

                alive = setInterval(()=>{
                    wsConnection.send(
                        JSON.stringify({
                            type: 'alive'
                        })
                    )
                }, ALIVE_INTERVAL)

                return
            }

            if(pkt.type==='signupmoderate'){
                if(pkt.status==='accept' || pkt.status==='reject'){

                    showSplasher()

                    await Promise.all([
                        vue.$store.dispatch('whoami'),
                        vue.$store.dispatch('setRoles')
                    ]) 
                    
                    const c = new Date()
                    internalLocHref('/home?'+c.getTime())

                }
                return
            }

            if(pkt.type==='openrolemoderate'){
                if(pkt.status==='accept'){

                    showSplasher()

                    await Promise.all([
                        vue.$store.dispatch('whoami'),
                        vue.$store.dispatch('setRoles')
                    ]) 
                    
                    hideSplasher()

                }
                return
            }

            if(pkt.type==='refresh'){

                    showSplasher()

                    await vue.$store.dispatch('whoami')
                    
                    hideSplasher()

                return
            }


            if(pkt.type==='notify'){
                vue.$store.dispatch('checkNewNotifies')
                return
            }

            if(pkt.type==='chatmsg'){

                if(pkt.room === 'team'){
                    
                    vue.$store.dispatch('newteamchathistory', {
                        team_id: pkt.room_id,
                        message: pkt.message
                    })
        

                } else if(pkt.room === 'club'){
                    
                    vue.$store.dispatch('newclubchathistory', {
                        club_id: pkt.room_id,
                        message: pkt.message
                    })
        

                }

            }

            if(pkt.type==='kick'){
                const c = new Date()
                vue.$router.push('/home?'+c.getTime())
                return
          
            }

        } catch (error) {
            console.log('bad pkt', m.data)
            return
        }
        

    }

    wsConnection.onclose = function(event) {

        if(alive){
            clearInterval(alive)
            alive = null
        }

        wsConnection = null
        setTimeout(()=>{initSocketClient(vue, user_id)}, RECONNECT_TIMEOUT)
        
        console.log(event.wasClean, 'Код: ' + event.code + ' причина: ' + event.reason);
    };
    
    wsConnection.onerror = function(error) {
        console.log("Ошибка " + error.message);
    };

    

}

export {
    initSocketClient
}