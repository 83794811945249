<template>

    <div>
        <div class="smooth" :class="{hidden: !flOpenedTeamMenu}"></div>



        <div ref="settingsModalTeam" v-if="teamOptions" id="team-settings" :class="{opened: flOpenedTeamMenu}">
                

                
                <div class="grip">
                    <hr>
                </div>
                <div class="wrapper">
                    <div>

                        <div class="pin-team-btn" :class="{pinned: +whoami.options.pinTeam===+focusTeamId}" @click="pinTeam()"></div>

                            <div class="team-mini-wrap" v-if="focusteam?.logo_id">
                                <img class="team-mini-ico" :src="ATT_URL+'pictures/'+focusteam.logo_id+'?s=75x75'">

                                <div class="team-mini-content">
                                    <div class="title">
                                        {{focusteam.title}}
                                    </div>

                                    <div v-if="focusteam.directions && focusteam.directions.length && refDirections.length" class="dirbox">
                                    <small class="smalldir" v-for="td of focusteam.directions" v-bind:key="td">
                                        {{directionById(td).title.toLowerCase()}}

                                    </small>
                                    </div>



                                </div>
                            </div>


                        <div class="options-buttons">


                            <div class="button-theme icof icoF509"   v-if="[14,15].includes(+whoami.role_id)">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goSettings" href="/teamsettings">Настройки команды</a></div>
                            </div>


                            <div class="button-theme icof icoF53F"  v-if="[14,15].includes(+whoami.role_id)">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goColorSettings" href="/teamcolorsettings">Вид</a></div>
                            </div>


                            <div class="button-theme icof icoF559"   v-if="[14].includes(+whoami.role_id)||(+whoami.id===+focusteam.owner_id&&[15].includes(+whoami.role_id))">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goTeamSelClub" href="#">Мой клуб</a></div>
                            </div>

                            <div class="button-theme icof icoF005" v-if="[16].includes(+whoami.role_id)">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goHallOfFame" :href="'/team/'+focusTeamId+'/halloffame'">Зал славы</a></div>
                            </div>

                            <div class="button-theme icof icoF05b" v-if="[14,15].includes(+whoami.role_id)">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goTargets" href="/teamsettings/targets">Цели команды</a></div>
                            </div>
                            <div class="button-theme icof icoF553">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goLineup" :href="'/lineup/'+focusTeamId">Состав команды</a></div>
                            </div>
                            <div class="button-theme icof icoF508" >
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goStaff" :href="'/staff/'+focusTeamId">Тренерский штаб</a></div>
                            </div>
                            <div class="button-theme icof icoF505">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="goFanclub" :href="'/fanclub/'+focusTeamId">Болельщики</a></div>
                            </div>
                            <div class="button-theme icof icoF1E0">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="doShare" href="#">Поделиться</a></div>
                            </div>

                            <div class="button-theme icof icoF2F5"  v-if="[16,18].includes(+whoami.role_id) || (+whoami.role_id===15 && +focusteam.owner_id!==+whoami.id)">
                                <div :class="'menu-item us-com '+teamOptions.buttons"> <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'teamleave', visible: true})" href="#">Выйти из команды</a></div>
                            </div>

                            
                            <BotMenu menu="teamleave" v-if="[15,16,18].includes(+whoami.role_id)">
                                <div class="qw">
                                    <div class="qw-title">Выйти из команды</div>
                                    <img class="qw-ico" :src="ATT_URL+'pictures/'+focusteam.logo_id" alt="">
                                    <div class="qw-desc">Вы действительно хотите покинуть команду «{{focusteam.title}}»?</div>
                                    <a @click.prevent="doTeamLeave" href="#" class="mdc-button__label alert">
                                        Покинуть команду
                                    </a>
                                    <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'teamleave', visible: false})" href="#" class="mdc-button__label">
                                        Отмена
                                    </a>

                                </div>
                            </BotMenu>

                        </div>

                    </div>
                </div>
        </div>

    </div>

</template>


<script>
    import {MxApi} from '../assets/js/mix/MxApi'
    import {MxStore} from '../assets/js/mix/MxStore'
    import Rainbow from '@/components/Rainbow.vue'
    import {swipe} from '@/assets/js/swiper'
    import BotMenu from '@/components/modal/BotMenu.vue'


    export default {
        name: 'TeamSettings',


        components: {
            Rainbow, BotMenu     
        },

        mixins:[MxApi, MxStore],

        mounted(){

            document.querySelector("html").style.touchAction = "pan-down"
            const thisComponent = this

            const t = setInterval(()=>{
                 const myblock = document.querySelector('#team-settings')

                if(myblock ){

                    clearInterval(t)
                    

                    swipe(myblock, { maxTime: 1000, minTime: 100, maxDist: 150,  minDist: 60 });
                    myblock.addEventListener("swipe", function(e) {
                        if(e.detail.dir==='down'){
                            thisComponent.$store.dispatch('toggleTeamMenu', false)
                        }
                    });


                }
            }, 100)


            document.addEventListener( 'click', (e) => {

                if(!this.flOpenedTeamMenu){
                    return
                }
                const div = this.$refs.settingsModalTeam
                const btn = document.querySelector('#team-btn-'+this.focusTeamId+'.team-settings-btn')
                const btn2 = document.querySelector('#btnid-c-t-'+this.focusTeamId)
// swiper-slide

                const withinBoundaries = e.composedPath().includes(div);
                const withinBoundaries2 = e.composedPath().includes(btn)
                const withinBoundaries3 = e.composedPath().includes(btn2)
            
                if ( ! withinBoundaries && ! withinBoundaries2 && ! withinBoundaries3) {
                    this.$store.dispatch('toggleTeamMenu', false)
                }
            })

        },

        computed:{
            // focusteam: function(){
            //     const t = this.whoami.teams.find((t)=>+t.id===+this.focusTeamId)
            //     if(t){
            //         return t
            //     }
            //     return {title:'',logo_id:''}
            // }
        },

        methods: {

            goSettings(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/teamsettings')
            },

            goColorSettings(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/teamcolorsettings')
            },

            goTeamSelClub(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/teamtoclub')

            },

            goHallOfFame(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/team/'+this.focusTeamId+'/halloffame')

            },

            goLineup(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/lineup/'+this.focusTeamId)

            },

            goTargets(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/teamsettings/targets')

            },


            goStaff(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/staff/'+this.focusTeamId)

            },

            goFanclub(){
                this.$store.dispatch('toggleProfileMenu', false)
                this.$store.dispatch('toggleTeamMenu', false)
                this.$router.push('/fanclub/'+this.focusTeamId)

            },

            doShare(){
                let shareData = {
                    title: 'Команда '+this.focusteam.title,
                    text: 'Команда '+this.focusteam.title,
                    url: 'https://application.cheerworld.ru/team/'+this.focusteam.title,
                }


                this.$store.dispatch('toggleBottomMenu', {menu: 'teamleave', visible: false})
                this.$store.dispatch('toggleTeamMenu', false)

                this.appShare(shareData)

            },

            async doTeamLeave(){
                
                if(+this.whoami.role_id===15){

                    await this.apiCall('POST', 'team/coachleave', 
                        {id: this.focusteam.id}
                    )


                } else {
                    await this.apiCall('POST', 'team/leave', 
                        {id: this.focusteam.id}
                    )

                }


                await this.$store.dispatch('whoami')

                this.$forceUpdate()

                if(this.whoami.teams?.length){
                    this.$store.dispatch('setFocusTeamId', this.whoami.teams.reverse()[0].id)

                }   else {
                    this.$store.dispatch('setFocusTeamId', null)
                }

                this.$store.dispatch('toggleBottomMenu', {menu: 'teamleave', visible: false})
                this.$store.dispatch('toggleTeamMenu', false)
                
                if(!this.whoami.teams?.length){

                    this.$router.push('/findteam')
                }

            },




            async pinTeam(){

                if(+this.whoami.options.pinTeam===+this.focusTeamId){
                    await this.$store.dispatch('setPinOption', null)
                } else {
                    await this.$store.dispatch('setPinOption', this.focusTeamId)                
                }

                this.apiCall('POST', 'user/options', 
                    {data: this.whoami.options}
                )
            }

        }

        
    }

</script>



<style scoped lang="scss">
#team-settings{
z-index: 21;
user-select: none;

.menu-item{
    // background-color: white;
    // margin: 0 40px;
    width: 100%;
    box-shadow: 2px 2px 3px;
    cursor: pointer;
    &:hover{
        box-shadow: 2px 2px 2px;

    }

    a{
        display: inline-block;
        width: 100%;
        padding: 8px 0;

    }

}


box-shadow: -2px -2px 2px white;

max-height: 0;

transition-property: 'max-height';
transition-duration: 650ms;
transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

&.opened{
    max-height: 100%;
}

// @keyframes slidein {
//   from {
//     transform: translateX(0%);
//   }

//   to {
//     transform: translateX(100%);
//   }
// }

// animation: slidein;

    // opacity: 0.1;
    // z-index: -2;
max-width: 500px;
    position: fixed;
    bottom: 0;
    width: 100%;
    // height: 480px;
    background-color: lightgray;
    border-radius: 25px 25px 0 0;

    .grip{
        cursor: grabbing;
        position: absolute;
        width: 100%;
        height: 25px;
        hr{
            background-color: black;
            width: 80px;
            position: absolute;
            left: 50%;
            top: 7px;
            transform: translateX(-50%);
        }
    }

    .wrapper{
        position: relative;
        height: 100%;
        margin-top: 25px;


        .options-buttons{

            display: grid;

            @media (max-height: 649px) {
                grid-template-columns: 1fr 1fr;
                font-size: 60%;

            }

            font-size: 100%;
            grid-template-columns: 1fr;


            .button-theme{

                    &.icof a::before{
                        margin-right: 12px;
                        margin-left: 20px;
                        font-family: 'Font Awesome 5 Free';
                        @media (max-width: 399px) {
                            display: none;
                        }


                    }

                    &.icoF005 a::before{
                        content: "\f005";
                    }

                    &.icoF05b a::before{
                        content: "\f05b";
                    }

                    &.icoF553 a::before{
                        content: "\f553";
                    }
                    &.icoF505 a::before{
                        content: "\f505";
                    }
                    &.icoF508 a::before{
                        content: "\f508";
                    }
                    &.icoF1E0 a::before{
                        content: "\F1E0";
                    }
                    &.icoF2F5 a::before{
                        content: "\F2F5";
                    }
                    &.icoF559 a::before{
                        content: "\F559";
                    }
                    &.icoF509 a::before{
                        content: "\F509";
                    }
                    &.icoF53F a::before{
                        content: "\F53F";
                    }
                    margin: 5px;
                    text-align: left;
                        @media (max-width: 399px) {
                            text-align: center;
                        }

                    font-family: 'Montserrat';
                    font-weight: 500;
                    // display: grid;
                    align-self: center;

                    a{
                        // display: grid;
                        @media (min-height: 700px) and (min-width: 400px) {
                            font-size: 130%;
                        }

                    }

            }

        }


    }
}

.pin-team-btn{
  position: absolute;
  right: 32px;
  top: 0px;
    opacity: 0.15;
  &.pinned{
    transform: scale(1.75);
    transition: all 0.15s ease-in ;
    opacity: 1;
    text-shadow: 1px 0 1px #fff, 0 0px 2px #fff, -1px 0 2px #fff, 0 -1px 4px #333;
    color: #333;
}


  &::before{
        content: "\f08d";
        font-family: 'Font Awesome 5 Free';
        cursor: pointer;
  }
}

.smalldir:not(:last-child)::after{
  content: ' / ';
}


.team-mini-wrap{
    display: flex;
    margin: 0 5px 10px 0;

    .team-mini-ico{
        width: 75px;
        height: 75px;
        align-self: center;
        margin-left: 50px;

    }
    .team-mini-content{
        .title{
            font-weight: 700;
            font-size: 16px;
        }
        align-self: center;
        margin: 0 50px 0 15px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;

    }
}
.dirbox small{
    opacity: 0.7;
    font-size: 10px;
    font-family: "Lato", sans-serif;
}
</style>