<template>
  <nav class="nav2-bar" v-if="$store&&whoami&&loaded">
    <div class="photo" @click="$router.push('/person/'+whoami.id)">

        <img :src="ATT_URL+'pictures/'+whoami.avatar_id+'?s=100x100'">

    
    </div>

    <div class="info-wrap">
        <div class="info">
            <div class="fio">
                <div>{{whoami['surname']}} </div>
                <span>{{whoami['name']}} </span>
                <span>{{whoami['patron']}} </span>
            </div>
            <div class="role">
                {{roleById(whoami['role_id']).title}}
            </div>
            <div class="directions">
                <span class="one-dir-str" v-for="onedir of userDirectionsTitles" v-bind:key="onedir">
                  {{onedir}}
                </span>
            </div>
        </div>
    </div>

    <div class="club" v-if="club">
        <div
            @click.prevent="goClub"
            :style="'background-image: url('+ATT_URL+'pictures/'+club.logo_id+')'"
        class="ico"></div>
        <div class="title">{{club.title}}</div>
    </div>

      <div class="bell-n-crown" :class="{active: newNotifiesFlag}">
        <div :class="{active: newNotifiesFlag}" class="bell" @click.prevent="$router.push('/notifies')">

      </div>


    </div>

  </nav>
</template>

<script>

import {MxStore} from "@/assets/js/mix/MxStore.js"
import {MxApi} from "@/assets/js/mix/MxApi.js"

export default {
  name: 'NavProfile',
    mixins:[MxStore, MxApi],



  components:{
    
  },

  computed:{
    club(){
      if(+this.whoami.role_id===14){
        const c = this.whoami.clubs.find((c)=>+c.id===+this.focusClubId)
        if(c){
          return c
        }
        return null
      }
      if(!this.focusTeamId){
        return null
      }
      const t = this.whoami?.teams?.find((t)=>+t.id===+this.focusTeamId)
      if(!t || !t.club || !t.club.length){
        return null
      }

      return t.club[0]

    }
  },

  data: ()=>({
    loaded: false,
  }),

  async mounted(){

    const pauseLen = 0 //this.refRoles.length?1:0 //+this.refRoles.length ? 0 : 10000;

    const q1 = this.$store.dispatch('setRoles')
    const q2 = this.$store.dispatch('setDirections')
    const q3 = this.$store.dispatch('setRegions')
    const q9 = this.$store.dispatch('setCities')
    const q4 = this.$store.dispatch('setDircodes')
    const q5 = this.$store.dispatch('checkNewNotifies')
    const q6 = this.$store.dispatch('setTeamRoles')
    const q7 = this.$store.dispatch('setLastNewsItem')
    
    const promises = [q1,q2,q3,q4,q5,q6,q7,q9];

    if(+this.whoami.role_id===16){
      promises.push(this.$store.dispatch('setHomeworksFlags'))
    }

    await Promise.all(promises)


    setTimeout(()=>{

       if(window.AndroidShareHandler){
         window.AndroidShareHandler.coreLoaded(true)
       }

       this.hideSplasher()
       this.$store.dispatch('setStartingLoaded')
       this.loaded = true


    }, pauseLen)


  },

  methods:{
    goClub(){
        this.$router.push('/club/'+this.club.id)
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .nav2-bar{

        &.hidden{
          display: none;
        }

        height: 70px;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .photo{
            flex: 1;
            margin: 50px 20px 20px 30px;
            @media (max-width: 399px) {
              margin: 50px 4px 20px 25px;
            }
            @media (max-width: 359px) {
              margin: 50px 4px 20px 8px;
            }

            min-width: 71px;
            min-height: 71px;
            max-width: 71px;
            max-height: 71px;
            // margin: 0 6px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            position: relative;
            .directions-ico-wrap{
                // position: absolute;
                // top: 90%;
                // left: 15px;//calc(50% + 4px);
                // display: flex;

                position: absolute;
                top: 10%;
                left: -25px;
                @media (max-width: 359px) {
                  left: -10px;
                }


                display: flex;
                flex-direction: column;

                >*{
                    width: 28px;
                    height: 28px;
                    // background-color: gray;
                    // border-radius: 14px;
                    &.tot1,
                    &.tot2{
                    margin-top: 0px;

                    }
                    &.tot3{
                      margin-top: -8px;
                    }
                    &.tot4{
                      margin-top: -10px;

                    }

                    &.tot5,
                    &.tot6{
                      margin-top: -14px;

                    }

                    // border: 1px solid white;
                }

            }

        }

        .info-wrap{
            flex: 1;
            display: grid;
            margin-top: 60px;

            .info{
                height: 100px;
                overflow: hidden;

                .fio{

                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                    font-size: 75%;
                    max-height: 48px;
                    overflow: hidden;
                }

                .role{
                    // margin-left: 12px;
                    // opacity: 0.7;
                    font-size: 60%;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;

                }
                .directions{
                    // opacity: 0.8;
                    font-size: 65%;
                    max-width: 175px;
                }
            }

        }

        .club{
            margin-top: 15px;
            margin-right: 17px;
            flex: 1;
            display: grid;
            min-width: 56px;
            min-height: 56px;
            max-width: 56px;
            max-height: 56px;
            .ico{
            width: 56px;
            height: 56px;
            border-radius: 28px;
            margin-right: 10px;
            background-size: cover;
            }

            position: relative;
            .title{
                font-family: 'Montserrat';
                font-weight: 500;
                position: absolute;
                text-align: center;
                transform: translateX(-50%);
                left: 50%;
                top: 103%;
                font-size: 80%;
                line-height: 12px;
                width: 110px;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -moz-box;
                -moz-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-clamp: 2;
                box-orient: vertical;

            }
            align-self: first baseline;


        }


        .bell-n-crown{
            flex: 0.5;
            width: 32px;
            max-width: 32px;

            margin: 10px 5px 5px 1px;




        }
        .bell-n-crown:not(.active){
          margin-top: -5px;
        }


        .bell{
            // background-image: url("@/assets/img/fa/Vector.svg");
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 32px;
            max-height: 32px;
            border-radius: 16px;
            
            
            position: relative;
            cursor: pointer;

            &.active{
              text-shadow: 2px 2px black;
              animation: anibellmove 20s linear infinite;

            }

            &.active::before{
                color: red;
                font-size: 200%;
            }
            &::before{
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  content: "\f0f3";
                  font-family: "Font Awesome 5 Free";
                  box-sizing: content-box;
            }
        }

    }

.dirico{
    display: inline-block;
    background-size: contain;
    background-position: center;
}

.one-dir-str{
  text-transform: lowercase;
}
.directions > .one-dir-str:not(:nth-last-child(1))::after{
  content: "/ ";
}

 
    @keyframes anibellmove {
        10.0%{
            transform: skew(0deg, 0deg);
        }

        10.1%{
            transform: skew(-7deg, 7deg);
        }
        10.2%{
            transform: skew(-10deg, -10deg);
        }
        10.3%{
            transform: skew(0deg, 0deg);
        }

        10.4%{
            transform: skew(7deg, 7deg);
        }
        10.5%{
            transform: skew(10deg, -10deg);
        }
        10.6%{
            transform: skew(0deg, -10deg);
        }
        10.7%{
            transform: skew(0deg, 0deg);
        }


        10.8%{
            transform: skew(0deg, 0deg);
        }

        10.9%{
            transform: skew(-7deg, 7deg);
        }
        11.0%{
            transform: skew(-10deg, -10deg);
        }
        11.1%{
            transform: skew(0deg, 0deg);
        }

        11.2%{
            transform: skew(7deg, 7deg);
        }
        11.3%{
            transform: skew(10deg, -10deg);
        }
        11.4%{
            transform: skew(0deg, -10deg);
        }
        11.5%{
            transform: skew(0deg, 0deg);
        }

        11.6%{
            transform: skew(0deg, 0deg);
        }

        11.7%{
            transform: skew(-7deg, 7deg);
        }
        11.8%{
            transform: skew(-10deg, -10deg);
        }
        11.9%{
            transform: skew(0deg, 0deg);
        }

        12.0%{
            transform: skew(7deg, 7deg);
        }
        12.1%{
            transform: skew(10deg, -10deg);
        }
        12.2%{
            transform: skew(0deg, -10deg);
        }
        12.3%{
            transform: skew(0deg, 0deg);
        }





        100%{
            transform: skew(0deg, 0deg);
        }

    }
</style>
