
import {attApiHttpRequest } from '@/assets/js/utils'

export const MxHomeWork = {

    methods:{
        async prepareDzFiles(files){

            const dzFiles = []
            const promises = []
            for(const fileId of files??[]){
                promises.push(
                    attApiHttpRequest('GET', 'files/meta/'+fileId, 
                        {}
                    )
                )
            }
            const vals = await Promise.all(promises)
            
            for(const pkt of vals){
            
                if(pkt.status === 200 &&pkt.packet.status.code === 200 && pkt.packet.data.id){
                    const item = {
                        title: pkt.packet.data.title,
                        id: pkt.packet.data.id,
                        link: this.ATT_URL+'files/'+ pkt.packet.data.id,
                        picture: !!pkt.packet.data.picture
                    }
                    dzFiles.push(item)
                }
            
            }
            return dzFiles
        },


        swap({src, dst}){

            const pics = this.locGallery.filter(f=>!!f.picture??false)
            const srcId = pics[src].id
            const dstId = pics[dst].id

            const dstIndex = this.locGallery.findIndex(f=>f.id === dstId)

            const movingIndex = this.locGallery.findIndex(f=>f.id === srcId)
            const movingItem = this.locGallery[movingIndex]

            this.locGallery.splice(movingIndex, 1)
            this.locGallery.splice(dstIndex, 0, movingItem)


            this.changes4leaver = true

        },

        swapDz({src, dst}){

            const pics = this.dzFiles.filter(f=>!!f.picture??false)
            const srcId = pics[src].id
            const dstId = pics[dst].id

            const dstIndex = this.dzFiles.findIndex(f=>f.id === dstId)

            const movingIndex = this.dzFiles.findIndex(f=>f.id === srcId)
            const movingItem = this.dzFiles[movingIndex]

            this.dzFiles.splice(movingIndex, 1)
            this.dzFiles.splice(dstIndex, 0, movingItem)

            this.changes4leaver = true

        },


        async doAddFile(){

            this.linkBarActive = false
            this.$nextTick(()=>{
                this.$refs.inputfile.click()
            })

        },

        readPreviewFile(e){
            if(!e.target.files.length){
                return
            }
            if(e.target.files.length!==1){
                return
            }
            this.uploadingProccess = true
            let attachName = e.target.files[0].name

            let ext = e.target.files[0].name.split('.').pop();

            const FR = new FileReader();
            let base64= '';

            const thisComponent = this;
            FR.addEventListener("load", async function(evt) {
                base64 = evt.target.result;


                const upload = await attApiHttpRequest('PUT', 'files', 
                    {
                        ext: ext,
                        file: base64,
                        title: attachName,
                        tags: ['userfile', 'app', 'hw']
                    }
                )            

                thisComponent.uploadingProccess = false

                if(!(upload.status===200&&upload.packet.status.code===200&&upload.packet.data.id)){
                    return {error: upload.packet.status.message}
                }

                const item = {
                    title: attachName,
                    id: upload.packet.data.id,
                    link: thisComponent.ATT_URL+'files/'+ upload.packet.data.id,
                    picture: upload.packet.data.picture
                }

                thisComponent.dzFiles.push(item)
                thisComponent.$refs.inputfile.value = ''
                
            }); 
                
            FR.readAsDataURL(e.target.files[0]);
        },

        },


}
