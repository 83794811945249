<template>

    <BotMenu menu="jointeamtoclub">
        <div class="qw" v-if="club">
            <div class="qw-title">Вступление команды «{{usedTeam.title}}» в клуб «{{club.title}}»</div>
          
            <div class="qw-ico-double">
                <img class="qw-ico" :src="ATT_URL+'pictures/'+usedTeam.logo_id" alt="">
                <span class="arrow-join"></span>
                <img class="qw-ico" :src="ATT_URL+'pictures/'+club.logo_id" alt="">
            </div>

            <div class="qw-desc">Вы действительно хотите отправить завяку на вступление в клуб? Это может занять некоторое время!</div>
            <a @click.prevent="doJoin" href="#join" class="mdc-button__label black">
                Отправить запрос
            </a>
            <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'jointeamtoclub', visible: false})" href="#" class="mdc-button__label">
                Отмена
            </a>

        </div>
    </BotMenu>
</template>

<script>
import BotMenu from '@/components/modal/BotMenu.vue'
import { ATT_URL} from '@/assets/js/config'
import {MxStore} from "@/assets/js/mix/MxStore.js"
import {MxApi} from "@/assets/js/mix/MxApi.js"

export default {
    name: 'JoinTeamToClubModal',
    props:{
      club: {
          type: Object,
          default: () => null
      },
      forceTeam: {
          type: Object,
          default: () => null
      }
    },
    computed:{
        ATT_URL: ()=> ATT_URL,
        // focusteam: function(){
        //     const t = this.whoami.teams.find((t)=>+t.id===+this.focusTeamId)
        //     if(t){
        //         return t
        //     }
        //     return {title:'',logo_id:''}
        // },

        usedTeam(){
            if(this.forceTeam){
                return this.forceTeam
            }
            return this.focusteam
        }

    },
    components:{BotMenu},

    mixins: [MxStore, MxApi],


    methods:{
        async doJoin(){
            this.$emit('joinedSended', this.club.id)

            this.$nextTick(async()=>{

                const clubs = await this.apiCall('POST', 'club/jointeam', {club_id: this.club.id, team_id: this.usedTeam.id})

                if(clubs.status===200&&clubs.packet.subcode===200){

                    this.$emit('joinedResponsed', this.club.id)
                    
                    
                }
            })

            this.$store.dispatch('toggleBottomMenu', {menu: 'jointeamtoclub', visible: false})

        }
    }
}
</script>

<style lang="scss" scoped>

</style>