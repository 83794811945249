var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('header',[_c('NavBar')],1),(_vm.globalHomeSpinner)?_c('main',{class:_vm.userOptions.background,style:('display: grid; height: 100vh;')},[_c('Spinner')],1):_c('main',{class:_vm.userOptions.background,attrs:{"id":"idmainloilc"}},[_c('div',{staticClass:"userbar",class:{
            'hidden-half': ['gymcat', 'gympos', 'shopcat', 'shoppos', 'news', 'newsread'].includes(_vm.$route.name),
            'hidden-full': !['home', 'gymcat', 'gympos', 'shopcat', 'shoppos', 'news', 'newsread', 'findteam', 'newteam', 'newclub'].includes(_vm.$route.name)
          }},[_c('NavProfile'),_c('NavPages')],1),(_vm.startingLoaded)?_c('div',{staticClass:"slot-con"},[_vm._t("default")],2):_vm._e(),(![
              'teamchat',
              'clubchat'

          ].includes(_vm.$route.name))?_c('footer',[_c('img',{attrs:{"src":require("@/assets/img/fa/logo_gorizontal_white 1.svg")}})]):_vm._e()]),_c('ProfileSettings'),_c('TeamSettings'),(+_vm.whoami.role_id===14)?_c('ClubSettings'):_vm._e(),_c('BotMenu',{attrs:{"menu":"extsite"}},[_c('div',{staticClass:"qw"},[_c('div',{staticClass:"qw-title"},[_vm._v("Открыть ссылку")]),_c('div',{staticClass:"qw-desc"},[_vm._v("Вы действительно хотите открыть ссылку \""+_vm._s(_vm.cutUrl(_vm.flVisibleBottom.extsite.extSiteAddr))+"\"?")]),_c('a',{staticClass:"mdc-button__label black",attrs:{"href":_vm.flVisibleBottom.extsite.extSiteAddr,"target":"_blank","rel":"nofollow"}},[_vm._v(" Перейти ")]),_c('a',{staticClass:"mdc-button__label",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('toggleBottomMenu', {menu: 'extsite', visible: false})}}},[_vm._v(" Отмена ")])])]),_c('LeaverModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }