<template>
    
    <BotMenu menu="leaver">
        <div class="qw">
            <div class="qw-title">Изменения будут потеряны</div>
            <div class="qw-desc">На странице есть не сохраненные данные, если вы  перейдете на другую страницу эти данные будут потеряны!?</div>
            <a @click.prevent="doLeave" href="#" class="mdc-button__label black">
                Перейти
            </a>
            <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'leaver', visible: false, surefn: null})" href="#" class="mdc-button__label">
                Отмена
            </a>

        </div>
    </BotMenu>
</template>


<script>
import BotMenu from '@/components/modal/BotMenu.vue'

export default {
    name: 'LeaverModal',
    components:{BotMenu},


    methods:{
        async doLeave(){

            this.$store.dispatch('leavePage')

        },
    }
}
</script>
