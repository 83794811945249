<template>
    <nav>
        <ul>
            <li
                :class="classFormula(['gymcat','gympos'])"
                id="linGym"
            >
                <router-link to="/gym/2">Спортзал</router-link>
            </li>
            <li
                id="linNews"
                :class="classFormula(['news','newsread'])"
            >
                <router-link to="/news">Новости</router-link>
            </li>

            <li
                id="linWorld"
                :class="classFormula('world')"
            >
                <router-link to="/world">Чирмир</router-link>
            </li>
            <li
                id="linShop"
                :class="classFormula(['shoppos','shopcat'])"
            >
                <router-link to="/shop/3">Магазин</router-link>
            </li>
            <li
                id="linChat"
                :class="classFormula('world')"
            >
                <router-link to="/">Чирчат</router-link>
            </li>

        </ul>
    </nav>
</template>


<script>
import {MxStore} from '@/assets/js/mix/MxStore.js'


export default {
  name: 'NavPages',

  mixins:[MxStore],

  methods:{
      classFormula(cl){

          let res
          if(typeof(cl)==='string'){
              res = this.$route.name===cl?'active ':''
          } else {
              res = cl.includes(this.$route.name)?'active ':''

          }

          //res += this.userOptions.buttons
          return res
      }
  }

}
</script>

<style lang="scss" scoped>

    *{
        user-select: none;
    }

    nav{
        max-width: 500px;
        &.hidden{
            display: none;
        }
    }

    ul{
        list-style-type: none;
        padding: 0 14px;
        margin: 0;
        display: flex;

        overflow: hidden;

        @media (max-width: 495px) {
            overflow-y: hidden;
            overflow-x: auto;

        }

        >li{
            background-color: white;
            min-width: 58px;
            flex: 1;
            text-align: center;
            margin: 8px 2px;
            padding: 8px 4px;
            
            @media (max-width: 399px) {
                margin: 8px 2px;
                padding: 8px 2px;
            }

            border-radius: 10px;
            font-size: 10px;
            text-transform: lowercase;
            white-space: nowrap;
            font-weight: 400;
            font-family: Montserrat;

            transition: all .3s ease-in-out;

            border: 1px solid transparent;

            &.active{
                // transform: scale(1.05);
                font-weight: 700;
                border: 1px solid;
            }

            a{
                text-decoration: none;
                color: #473434!important;
                text-shadow: 1px 1px 2px grey;
            }

            &#linGym a::before{
                
                content: "\F434";
                font-family: "Font Awesome 5 Free";
                display: block;
                font-size: 24px;
                margin-bottom: 6px;

            }
            &#linNews a::before{
                
                content: "\F1EA";
                font-family: "Font Awesome 5 Free";
                display: block;
                font-size: 24px;
                margin-bottom: 6px;

            }
            &#linShop a::before{
                
                content: "\F218";
                font-family: "Font Awesome 5 Free";
                display: block;
                font-size: 24px;
                margin-bottom: 6px;

            }
            &#linWorld a::before{
                
                content: "\F57D";
                font-family: "Font Awesome 5 Free";
                display: block;
                font-size: 24px;
                margin-bottom: 6px;

            }
            &#linChat{

                &::after{
                    content: "\00a0";
                    display: block;
                    height: 0;
                    width: 90px;
                }

                a::before{
                    
                    content: "\F086";
                    font-family: "Font Awesome 5 Free";
                    display: block;
                    font-size: 24px;
                margin-bottom: 6px;

                }

            } 

        }
    }

    

</style>