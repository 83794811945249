<template>

    <div class="news-item" 
          @click="$router.push('/news/read/'+item.id)"
    >

        <h3>{{item.title}}</h3>
        <div class="sub">{{item.sub_title}}</div>


        <div class="img2">
            <img :src="ATT_URL+'pictures/'+item.picture" alt="">
        </div>



        <div class="at">
            {{item.public_at}}

        </div>

    </div>

</template>

<script>
import { MxStore } from '@/assets/js/mix/MxStore'

export default {
    name: 'NewsItem',
    mixins:[MxStore],

    props: {

      item: {
          type: Object,
          default: () => {}
      },




    },
    methods:{

    }

}
</script>

<style lang="scss" scoped>

.news-item{

  cursor: pointer;
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 15px;
  margin: 5px 5px 25px 5px;

  h3{
    margin: 0;
    font-family: 'Montserrat';

  }
  .sub{
      text-align: justify;
      margin-bottom: 4px;
  }
  .at{
      margin-top: 12px;
      text-align: right;
  }
  p{
    margin: 0;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;

      text-align: center;

  }

  .img2 img {
      width: 100%;
      height: auto;
  }
  


}
</style>