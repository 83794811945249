<template>
    <div class="gallery-show">
        <div class="logos" v-if="!inited">
            <img src="@/assets/img/android-chrome-192x192.png">
        </div>
        <div class="images">
          <SsrCarousel v-model="locFocusIndex">

            <div class="slide" :index="itemindex" v-for="(item, itemindex) of galleryItems" v-bind:key="item.id">
                <img :src="ATT_URL+'pictures/'+item.id" alt="">
            </div>

          </SsrCarousel>
        </div>

        <div class="bar">
            <button @click="$emit('closed')"><div class="button btn-back"></div></button>
            <button @click="doShare(galleryItems[locFocusIndex])"><div class="button btn-share"></div></button>
            <a class="button btn-download" :href="ATT_URL+'pictures/'+galleryItems[focusId]?.id" download target="_blank">
                <button></button>
            </a>
            <span class="ftitle">{{1+locFocusIndex}}/{{galleryItems.length}}</span>

        </div>
    </div>
</template>

<script>
import { MxStore } from '@/assets/js/mix/MxStore'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

export default {
    name: "GalleryShow",
    mixins: [MxStore],
    components: {SsrCarousel},
    props:{
        files: {
            type: Array,
            default: () => []
        },
        focusId: {
            type: Number,
            default: () => null
        },
    },

    computed:{
        galleryItems(){
            return this.files.filter(f=>!!f.picture)
        }
    },

    data: ()=>({
        locFocusIndex: 0,
        inited: false
    }),

    mounted(){
        this.locFocusIndex = this.focusId
        setTimeout(()=>{
            this.inited = true
        }, 1300)
    },

    methods:{
        doShare(f){
            let shareData = {
                title: '',
                text: '',
                url: String(f.link).replace('/files/','/pictures/'),
            }

            this.$store.dispatch('toggleBottomMenu', {menu: 'teamleave', visible: false})
            this.$store.dispatch('toggleTeamMenu', false)

            this.appShare(shareData)
        }
    }

}
</script>

<style lang="scss" scoped>

@keyframes showgal {
    0%{
        opacity: 0;
    }
    70%{
        opacity: 0;
        transform: scale(0.3);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }

}

@keyframes fade {
    0%{
        opacity: 0;
        transform: scale(1.2);
    }
    15%{
        opacity: 1;
    }
    40%{
        opacity: 0.7;
        transform: scale(1);

    }
    60%{
        opacity: 0;
        transform: scale(0);

    }
    100%{
        opacity: 0;
    }

}

    .gallery-show{
        background-color: black;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 2;
        .bar{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 35px;
            display: flex;

            span.ftitle{
                flex: 10;
                color: wheat;
                padding: 5px 15px;
                font-family: 'Montserrat';
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: calc(100vw - 70px);
                overflow: hidden;
            }

            button {
                flex: 1;
                max-width: 40px;
                width: 40px;
                .button.btn-back{
                    &::before{
                        content: "\f060";
                    }
                }
                .button.btn-share{
                    &::before{
                        content: "\f1e0";
                    }
                }
                font-family: "Font Awesome 5 Free";
            }

            a.button{
                flex: 1;
                max-width: 40px;
                width: 40px;

                button{
                    display: block;
                    height: 100%;
                    &::before{
                        content: "\F019";
                    }
                }
                font-family: "Font Awesome 5 Free";

            }

        }

        .images{
            opacity: 1;
            animation: showgal 1.2s ease-in;
        }

    }

    .slide{
        height:calc(100vh - 40px);
        display: grid;
        align-content: space-evenly;
        img{
            
            width: 100%;
            @media (max-width: 500px) {
                max-width: 100vw;    
            }
            height: auto


        }



    }

    .logos{
        animation: fade 1.2s ease-in;
        transform: scale(0);

        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        text-align: center;
        background-color: black;
        z-index: 3;
        img{
            margin: auto;
        }
    }

</style>