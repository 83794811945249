<template>
    <div class="wrap" v-if="team">

          <div class="us-com team-box" :class="team.options.background">

                <FavBar
                  entity="team"
                  :favId="+team.id"
                />

                <div class="team-top">
                  <div class="coaches">

                    
                      <router-link :to="'/person/'+team.id_coach_1" v-if="team.id_coach_1">
                        <figure class="coach1">
                          <img :src="ATT_URL+'pictures/'+team.avatar_coach_1+'?s=40x40'">
                          <figcaption><div class="coachword">Тренер</div>{{shortFio(team.coach_1)}}</figcaption>
                        </figure>

                      </router-link>

                      <router-link :to="'/person/'+team.id_coach_2" v-if="team.id_coach_2">
                        <figure class="coach2">
                          <img :src="ATT_URL+'pictures/'+team.avatar_coach_2+'?s=40x40'">
                          <figcaption><div class="coachword">Тренер</div>{{shortFio(team.coach_2)}}</figcaption>
                        </figure>

                      </router-link>

                  </div>
                  <div class="logo">
                    <div class="img"
                    :style="'background-image: url('+ATT_URL+'pictures/'+team.logo_id+'?s=150x150)'"
                  >
                    </div>


                  </div>
                  
                  <div class="club"></div>

                </div>
                <div class="text">

                  <div><strong>{{team.title}}</strong></div>
                  <div class="group group-dir">
                    <div v-if="team.directions && team.directions.length && refDirections.length" class="dirbox">
                      <small class="smalldir" v-for="td of team.directions" v-bind:key="td">
                        {{directionById(td).title.toLowerCase()}}

                      </small>
                    </div>

                  </div>

                  <div class="group group-codes">

                    <div v-if="team.dircodes && team.dircodes.length && refDircodes.length" class="dirbox">
                      <small class="smalldir" v-for="td of team.dircodes" v-bind:key="td">

                        <span v-if="dircodeById(td) && dircodeById(td).code">
                          {{dircodeById(td).code}}
                        </span>
                      </small>
                    </div>

                    <div v-if="team.agegroup" class="agebox">
                        <small>{{team.agegroup.replaceAll(',',' / ')}}</small>
                    </div>


                  </div>

                  <div class="group group-geo">

                      <div>
                        <small>Россия</small>
                      </div>


                      <div v-if="team.region_title">
                        <small>
                          {{team.region_title}}
                        </small>
                      </div>

                      <div v-if="team.city_title">                   
                        <small>
                          {{team.city_title}}
                        </small>
                      </div>

                  </div>



                </div>

                <div class="found-text">
                    <div class="fyear" v-if="team.foundation">
                        Команда основана в {{team.foundation}} году
                    </div>
                    <div class="fstory">
                        {{team.story}}
                    </div>
                </div>


                <div class="alone-cubes">
                  <CubeItem 
                      class="alone-cube"
                      ico="f553"
                      caption="Состав"
                      @push="$router.push('/band/'+team.id)"
                  />
                  <CubeItem 
                      class="alone-cube"
                      ico="f5a2"
                      caption="Зал славы"
                      @push="$router.push('/team/'+team.id+'/halloffame')"
                  />
                </div>


                <div v-if="locGallery.length" class="gallery">
                    Галерея команды:

                </div>
                <GalleryFlow
                  :files="locGallery"
                  :mode4pl="true"
                />


                <LinksBar
                    :sourceLinks="team.links??[]"
                />

                <LikeBar
                    :likes="+team.likes"
                    :liked="!!+team.is_liked"
                    :setlike="async()=>{const diff = await setTeamLike(team.id);team.likes= (+team.likes) + (+diff); if(diff!=0){team.is_liked = (diff===-1?'0':'1')}}"
                    :transit="()=>($router.push('/team/'+team.id))"
                    :transitParam="team.id"
                    :onmap="!team.address?null:()=>{$router.push('/world?team='+team.id)}"
                >
                
                      <template v-slot:joinbtn v-if="isShowJoinBtn">
                        <div class="join"
                          @click.prevent="pushJoin"
                        >
                        
                          <DotDotDot
                            v-if="+isShowInviteMenu"
                            class="mask c-items-la"
                            :items="inviteItems()"
                            :obj="team"
                            objTitle="Пригласить в клуб"
                          />

                        </div>
                      </template>
                
                </LikeBar>

                <AlreadySendJoinModal
                    v-if="+whoami.role_id!==14"
                />

                <JoinTeamModal
                    v-if="+whoami.role_id!==14"
                    :team="team"
                    @joinedSended="isAlreadySendJoin=true"
                />

                <InviteTeamToClubModal
                    v-if="+whoami.role_id===14 && whoami.clubs && whoami.clubs.length"
                    :club="invite2club"
                    :forceTeam="team"
                    @inviteSended="inviteSended"

                />


              </div>

    </div>
</template>

<script>
// @ is an alias to /src
import {MxStore} from '@/assets/js/mix/MxStore.js'
import {MxParse} from '@/assets/js/mix/MxParse.js'
import {MxLikes} from '@/assets/js/mix/MxLikes.js'
import LikeBar from '@/components/LikeBar.vue'
import LinksBar from '@/components/links/LinksBar.vue'
import JoinTeamModal from '@/components/modal/JoinTeamModal.vue'
import AlreadySendJoinModal from "@/components/modal/AlreadySendJoinModal.vue"
import FavBar from '@/components/FavBar.vue'
import DotDotDot from '@/components/DotDotDot.vue'
import InviteTeamToClubModal from "@/components/modal/InviteTeamToClubModal.vue"
import GalleryFlow from "@/components/gallery/GalleryFlow.vue"
import { MxHomeWork } from '@/assets/js/mix/MxHomeWork'
import CubeItem from '@/components/uikit/CubeItem.vue'


export default {
  name: 'VetrineTeam',
  components:{FavBar, LikeBar, LinksBar, JoinTeamModal,
              DotDotDot, InviteTeamToClubModal, AlreadySendJoinModal,
              GalleryFlow, CubeItem},
  mixins:[MxStore, MxParse, MxLikes, MxHomeWork],

  props:{
      team: {
          type: Object,
          default: () => {}
      },
    
  },

  data: ()=> ({
      invites: {},
      invite2club: null,
      isAlreadySendJoin: false,
      locGallery: [],

  }),

  async mounted(){

      this.locGallery = await this.prepareDzFiles(this.team.gallery)

      if(+this.whoami.role_id !== 14){
        const alreqQ = await this.apiCall('GET', 'request/list', {})

        if(alreqQ.status===200&&alreqQ.packet.subcode===200&&alreqQ.packet.data.items){
            this.isAlreadySendJoin = !!alreqQ.packet.data.items.find(r=>+r.target_id===+this.team.id)
        }
      }

      if(+this.whoami.role_id === 14 && this.whoami.clubs){

        for(const c of this.whoami.clubs){
          const ckey = +c.id
          this.invites[ckey] = []
        }


        const clubs = this.whoami.clubs.map(c=>+c.id)
        const i = await this.apiCall('GET', 'invite/list', {clubs})
        if(+i.status===200 && i.packet.subcode===200){
          this.invites = i.packet.data.items
        }
        



      }

  },

computed:{


      isShowJoinBtn(){
        
        return this.isShowInviteMenu || (
          +this.whoami.role_id!==14
          && !this.whoami.teams?.find(t=>+t.id===+this.team.id)
        )

        
        
      },

      isShowInviteMenu(){
        return +this.whoami.role_id === 14 && this.whoami.clubs.filter(c=>+c.owner_id===+this.whoami.id).length>0
      },




},

methods:{

      pushJoin(){
        if(+this.whoami.role_id!==14){
          
            if(this.isAlreadySendJoin){
                this.$store.dispatch('toggleBottomMenu', {menu: 'alreadysendjoin', visible: true})
            } else {
                this.$store.dispatch('toggleBottomMenu', {menu: 'jointeam', visible: true})
            }
        }
      },


      inviteItems(){
        

          function calcStatus(_c, _t, _i){

            if(_c.teams && _c.teams.find(t=>+t.id===+_t.id)){
              return 'уже в клубе'
            }

            if(_i[+_c.id] && _i[+_c.id].find(cid=>cid===+_t.id)){
              return 'приглашение отправлено'
            }

            return ''

          }

          const menus = this.whoami.clubs
              .filter(c=>+c.owner_id===+this.whoami.id)
              .map((c)=>({
                title: c.title,
                action: this.inviteFromDDD,
                subj: c,
                iconUrl: this.ATT_URL+'pictures/'+c.logo_id,
                disabled: !!calcStatus(c, this.team, this.invites),
                status: calcStatus(c, this.team, this.invites),
                class: calcStatus(c, this.team, this.invites)==='уже в клубе'?'ddd-sta-green':'',
                
              }))

          return menus

      },  

    inviteFromDDD(subjClub, obj){
        this.invite2club = subjClub
        //this.forceTeam = subjTeam
        // todo  modal invite
        this.$store.dispatch('toggleBottomMenu', {menu: 'inviteteamtoclub', visible: true})
      
    },

    inviteSended(id){

        if(!this.invites.hasOwnProperty(+this.invite2club.id)){
          this.invites[+this.invite2club.id] = []
        }
        this.invites[+this.invite2club.id].push(+this.team.id)
        this.$forceUpdate()
    }

}


}
</script>


<style lang="scss" scoped>
.wrap{
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
  min-width: calc(100% - 20px);

}

    .found-text{
        margin: 10px;
        .fyear{
            margin: auto;
            text-align: center;
            margin-bottom: 9px;
        }

        .fstory{
            text-align: justify;
            font-size: 10px;
        }
    }

.team-box{

  box-shadow: 1px 0 1px #333, 0 0px 2px #333, -1px 0 2px #333, 0 -1px 4px #333;


  position: relative;
  margin: 5px;
  border-radius: 20px;
  padding: 5px;
  min-width: calc(100% - 20px);

}

.team-top{
  display: flex;
  margin-top: 8px;
  margin-bottom: 13px;

  .coaches{
    flex: 1;
    display: grid;
    // margin-top: 25px;

    .coach1, .coach2{

      // background-color: gray;
      border-radius: 50%;
      margin: 0;
      text-align: center;
      figcaption{
         .coachword{
           
           font-weight: 500;
           font-family: Montserrat;
         }

         font-size: 60%;
         text-align: center;
      }
      img{
        height: 40px;
        width: 40px;
        border-radius: 50%;

      }
    }

  }
  .club{
    flex: 1;
    // margin-top: 25px;
  }
 .logo{
    flex: 2;
    // max-width: 30vw;
    
    .img{
      margin: auto;
      width: 150px;
      border-radius: 50%;
      height: 150px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

    }

    img{
      // width: 100%;
      height: 100%;
      max-width: 150px;
      max-height: 150px;  
      border-radius: 50%;
    }
  }



}

.text{
  text-align: center;
  max-width: 205px;
  margin: auto;



  .group{
    margin-top: 6px;
    line-height: 15px;

  }

  strong{
      font-family: 'Montserrat';

  }
}


.alone-cubes{
  display: flex;
  gap: 3px;
  > *:nth-child(2n){
      margin: auto auto auto 3px!important;
  }
  > *:nth-child(2n+1){
      margin: auto 3px auto auto!important;
  }
}

.targets{
  margin: 25px 12px 12px 12px;

  * {
    padding: 10px 12px;
    text-align: center;
    background-color: lightgray;
    border-radius: 4px;
    margin: 8px 2px;
  }
}


.smalldir:not(:last-child)::after{
  content: ' / ';
}

.join{
  position: relative;
}
.gallery{
    text-align: center;
    text-decoration: underline;
    margin: 25px 0 12px 0;
}
</style>