// const API_URL =  'http://iloveapi/'
 const API_URL =  'https://api.cheerworld.ru/'

// const WSS_URL = 'ws://localhost:3080'
const WSS_URL = 'wss://ws.cheerworld.ru'

const YMAP_APIKEY = '3985ab22-1bc0-48ed-bdfd-90e411d3d4ec'
const BASE_URL = 'https://application.cheerworld.ru/'
// const BASE_URL = 'http://localhost:8080/'

const ATT_URL = 'https://attach.cheerworld.ru/'

export {
    API_URL, 
    WSS_URL,
    ATT_URL,
    BASE_URL,
    // WS_URL,
    YMAP_APIKEY,
}