import {isURL} from '@/assets/js/utils'

export const MxParse = {

    methods:{
        
        parseUnknown(val){

            return !this.detectVkontakte(val)
            && !this.detectTelegram(val)
            && !this.detectInstagram(val)
            && !this.detectFacebook(val)
            && !this.detectYoutube(val)


        },

        parseKnown(val){

            return this.detectVkontakte(val)
            || this.detectTelegram(val)
            || this.detectInstagram(val)
            || this.detectFacebook(val)
            || this.detectYoutube(val)

        },

        isURL(val){
            return isURL(val)
        },

        checkBlackList(val){
            if (
                val.match(/^(http|https)\:\/\/(www\.|)(.*\.|)instagram\.(ru|com)\//)
                || val.match(/^(http|https)\:\/\/(www\.|)(.*\.|)facebook\.(ru|com)\//)
                )
            {
                return 'Запрещено на территории РФ'
            }
            return true
             

        },

        detectVkontakte(val){
            return val.match(/^(http|https)\:\/\/(www\.|)(.*\.|)vk\.(ru|com)\//)

        },

        detectTelegram(val){
            return val.match(/^https\:\/\/(www\.|)t\.me\//)

        },

        detectInstagram(val){
            return val.match(/^(http|https)\:\/\/(www\.|)(.*\.|)(.*\.|)instagram\.(ru|com)\//)

        },

        detectFacebook(val){
            return val.match(/^(http|https)\:\/\/(www\.|)(.*\.|)facebook\.(ru|com)\//)

        },

        detectYoutube(val){
            return val.match(/^(http|https)\:\/\/(www\.|)(.*\.|)youtube\.(ru|com)\//)

        },

        youtubeEmbedCode(val){
            var r = val.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
            if(r[2] !== undefined) {
                return r[2].split(/[^0-9a-z_\-]/i)[0]
            } else {
                r = val.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)
                if(r && r[1] !== undefined) {
                    return r[1]
                }
                else return val
            }
        },
        youtubeEmbedLink(val){
            return `https://www.youtube.com/embed/${this.youtubeEmbedCode(val)}?autoplay=0&mute=0`
            
        },

        detectHttps(val){
            if (this.detectVkontakte(val)
                || this.detectTelegram(val)
                || this.detectInstagram(val)
                || this.detectFacebook(val)
                || this.detectYoutube(val)){
                    return  false
                }
            return val.match(/^(http|https)\:\/\/(www\.|)/)

        }




    }

}