export default function guest ({ next, store }){

    if(store.getters.isUserAuth){

        if(!store.getters.isFullRegister){

            return next({
                name: 'welcome'
             })
    
        }

        return next({
           name: 'home'
        })
    }
   
    return next()
   }