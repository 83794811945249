import { appApiHttpRequest, getTimezone } from "../utils";
import { API_URL} from '@/assets/js/config'


export const MxApi = {

    methods:{
        async apiCall(method, route, payload, toast = ""){

            if(toast){
                const response = await appApiHttpRequest(method, route, payload)
                if(toast && response?.status===200&&response?.packet?.subcode===200){
                    
                    if(window?.AndroidShareHandler?.popupToast){
                        window.AndroidShareHandler.popupToast(toast)
                    }
                }
                return response
    
            }
            
            return appApiHttpRequest(method, route, payload)
    
        },

        getTimezone(){
            return getTimezone()
        },

        saveFile(fileName,urlFile){


            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            a.href = urlFile;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(urlFile);
            a.remove();
        },

    },

    computed:{
        API_URL:()=>API_URL,

    }

}