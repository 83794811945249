var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.club)?_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"us-com club-box",class:_vm.club.options.background},[_c('FavBar',{attrs:{"entity":"club","favId":+_vm.club.id}}),_c('div',{staticClass:"club-top"},[_c('div',{staticClass:"bosses"},_vm._l((_vm.club.bosses.slice(0, 2)),function(boss){return _c('router-link',{key:boss.id,attrs:{"to":'/person/' + boss.id}},[_c('figure',{staticClass:"boss1"},[_c('img',{attrs:{"src":_vm.ATT_URL + 'pictures/' + boss.avatar_id + '?s=40x40'}}),_c('figcaption',[_c('div',{staticClass:"coachword"},[_vm._v("Руководитель")]),_vm._v(" "+_vm._s(_vm.shortFio(boss))+" ")])])])}),1),_c('div',{staticClass:"logo"},[_c('div',{staticClass:"img",style:('background-image: url(' +
            _vm.ATT_URL +
            'pictures/' +
            _vm.club.logo_id +
            '?s=150x150)')})]),_c('div',{staticClass:"club"})]),_c('div',{staticClass:"text"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.club.title))])]),_c('div',{staticClass:"group group-dir"},[(
            _vm.club.directions && _vm.club.directions.length && _vm.refDirections.length
          )?_c('div',{staticClass:"dirbox"},_vm._l((_vm.club.directions),function(td){return _c('small',{key:td,staticClass:"smalldir"},[_vm._v(" "+_vm._s(_vm.directionById(td).title.toLowerCase())+" ")])}),0):_vm._e()]),_c('div',{staticClass:"group group-codes"},[(_vm.club.agegroup)?_c('div',{staticClass:"agebox"},[_c('small',[_vm._v(_vm._s(_vm.club.agegroup.replaceAll(",", " / ")))])]):_vm._e()]),_c('div',{staticClass:"group group-geo"},[_vm._m(0),(_vm.club.region_title)?_c('div',[_c('small',[_vm._v(" "+_vm._s(_vm.club.region_title)+" ")])]):_vm._e(),(_vm.club.city_title)?_c('div',[_c('small',[_vm._v(" "+_vm._s(_vm.club.city_title)+" ")])]):_vm._e()])]),_c('div',{staticClass:"found-text"},[(_vm.club.foundation)?_c('div',{staticClass:"fyear"},[_vm._v(" Основан в "+_vm._s(_vm.club.foundation)+" году ")]):_vm._e(),_c('div',{staticClass:"fstory"},[_vm._v(" "+_vm._s(_vm.club.story)+" ")])]),(_vm.club.teams.length)?_c('div',{staticClass:"cteams"},[_c('strong',[_vm._v("Команды")]),_c('TeamsParade',{attrs:{"teams":_vm.club.teams},on:{"select":(t) => {
            _vm.$router.push('/team/' + t.id);
          }}})],1):_vm._e(),_c('div',{staticClass:"alone-cubes"},[_c('CubeItem',{staticClass:"alone-cube",attrs:{"ico":"f5a2","caption":"Зал славы"},on:{"push":function($event){return _vm.$router.push('/club/' + _vm.club.id + '/halloffame')}}})],1),(_vm.locGallery.length)?_c('div',{staticClass:"gallery"},[_vm._v("Галерея клуба:")]):_vm._e(),_c('GalleryFlow',{attrs:{"files":_vm.locGallery,"mode4pl":true}}),_c('LinksBar',{attrs:{"sourceLinks":_vm.club.links ?? []}}),_c('LikeBar',{attrs:{"likes":+_vm.club.likes,"liked":!!+_vm.club.is_liked,"setlike":async () => {
          const diff = await _vm.setClubLike(_vm.club.id);
          _vm.club.likes = +_vm.club.likes + +diff;
          if (diff != 0) {
            _vm.club.is_liked = diff === -1 ? '0' : '1';
          }
        },"transit":() => _vm.$router.push('/club/' + _vm.club.id),"transitParam":_vm.club.id,"onmap":!_vm.club.address
          ? null
          : () => {
              _vm.$router.push('/world?club=' + _vm.club.id);
            }},scopedSlots:_vm._u([(_vm.isShowJoinBtn)?{key:"joinbtn",fn:function(){return [_c('div',{staticClass:"join",on:{"click":function($event){$event.preventDefault();return _vm.pushJoin.apply(null, arguments)}}},[(+_vm.isShowJoinMenu)?_c('DotDotDot',{staticClass:"mask c-items-la",attrs:{"items":_vm.joinT2CItems(),"obj":_vm.club,"objTitle":'Вступить в клуб ' + _vm.club.title}}):_vm._e()],1)]},proxy:true}:null],null,true)}),(+_vm.whoami.role_id === 14)?_c('AlreadySendJoinModal'):_vm._e(),(+_vm.whoami.role_id === 14)?_c('JoinClubModal',{attrs:{"club":_vm.joinedclub},on:{"joinedSended":function($event){_vm.isAlreadySendJoin = true}}}):_vm._e(),(+_vm.whoami.role_id === 15 && _vm.whoami.teams && _vm.whoami.teams.length)?_c('JoinTeamToClubModal',{attrs:{"club":_vm.joinedclub,"forceTeam":_vm.forceTeam},on:{"joinedSended":_vm.joinedSended,"joinedResponsed":_vm.joinedResponsed}}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('small',[_vm._v("Россия")])])
}]

export { render, staticRenderFns }