<template>
    <div>
    

    <div v-if="!mode4pl" class="gallery-flow" :key="'gf-dndkey_'+dndkey">
        
        <draggable
            v-model="locGalleryItems"
            @change="change"
            draggable=".gitem"
            :handle="dnd?'.gallery-item':'none'"
            
        >
            <div draggable="true" class="gitem" v-for="(item, itemindex) of locGalleryItems" v-bind:key="item.id" style="position: relative;">
                <div draggable="false" class="gallery-item" @click.prevent="doStartShow($event, itemindex)" >
                    <img draggable="false" :src="ATT_URL+'pictures/'+item.id+'?s=200x200'" alt="">

                </div>

                <div class="row-del"
                    v-if="showDelIcon" 
                    @click="doSureDelItem(item)"
                ></div>
                <a class="row-show"
                    :href="ATT_URL+'pictures/'+item.id"
                    @click.prevent="doStartShow($event, itemindex)"
                ></a>


            </div>
        </draggable>
        

    </div>

    <div v-else class="gallery-flow-ex mode4pl">
        <div
            :class="{
                'one-items-line': index1===(galleryItemsBy4.length-1) && galleryItems.length-(index1*4) === 1,
                'two-items-line': index1===(galleryItemsBy4.length-1) && galleryItems.length-(index1*4) === 2,
                'three-items-line': index1===(galleryItemsBy4.length-1) && galleryItems.length-(index1*4) === 3,
            }" 
            class="gallery-m4-wrap-row" v-for="(row, index1) of galleryItemsBy4" v-bind:key="'row__'+index1">
            <div
                class="gallery-m4-row" v-for="(item, index2) of row" v-bind:key="item.id" @click.prevent="doStartShow($event, index1*4+index2)" >
                <img :src="ATT_URL+'pictures/'+item.id+'?s=100x100'" alt="">
                <div class="row-del"
                    v-if="showDelIcon" 
                    @click="doSureDelItem(item)"
                ></div>
            </div>
        </div>
    </div>



    <GalleryShow v-if="focusId!==null" :files="files" :focusId="+focusId" @closed="focusId=null;" />

    <BotMenu menu="killgalitem">
        <div class="qw">
            <div class="qw-title">Удалить изображение</div>
            <div class="qw-desc">Вы действительно хотите удалить это изображение?</div>
            <img class="qw-ico" :src="!deletedItem?'':ATT_URL+'pictures/'+deletedItem.id" alt="">
            <a @click.prevent="doDelItem" href="#" class="mdc-button__label black">
                Удалить изображение
            </a>
            <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'killgalitem', visible: false})" href="#" class="mdc-button__label">
                Отмена
            </a>

        </div>
    </BotMenu>

    </div>
</template>

<script>
import { MxStore } from '@/assets/js/mix/MxStore'
import GalleryShow from "@/components/gallery/GalleryShow.vue"
import BotMenu from '@/components/modal/BotMenu.vue'
import draggable from 'vuedraggable'

export default {
    name: "GalleryFlow",
    mixins: [MxStore],
    components: {GalleryShow, BotMenu, draggable},
    props:{
        files: {
            type: Array,
            default: () => []
        },
        showDelIcon: {
            type: Boolean,
            default: () => false
        },
        showDownloadIcon: {
            type: Boolean,
            default: () => false
        },
        mode4pl: {
            type: Boolean,
            default: () => false
        },
        dnd: {
            type: Boolean,
            default: () => false
        },
        
    },

    computed:{
        galleryItems(){
            return this.files.filter(f=>!!f.picture??false)
        },
        galleryItemsBy4(){

            const res = []
            const chunkSize = 4

            for (let i = 0; i < this.galleryItems.length; i += chunkSize) {
                const chunk = this.galleryItems.slice(i, i + chunkSize)
                res.push(chunk)
            }
            return res
        }
    },

    data: () => ({
        focusId: null,
        deletedItem: null,

        locGalleryItems: [],
        dndkey: 1
    }),

    mounted(){
        this.locGalleryItems = this.galleryItems
    },

    watch:{

        files:{
            handler: function(){
                this.locGalleryItems = this.galleryItems
                this.dndkey++
            },
            deep: true
        },

    },

    methods:{

        


        change(a){
            if(a?.moved){
                this.$emit('swap', {dst: a.moved.newIndex, src: a.moved.oldIndex})
            }
            
        },

        async doDelItem(){
            this.$store.dispatch('toggleBottomMenu', {menu: 'killgalitem', visible: false})
            this.$emit('delete', this.deletedItem)

        },

        doSureDelItem(item){
            this.deletedItem = item
            this.$store.dispatch('toggleBottomMenu', {menu: 'killgalitem', visible: true})
        },

        doStartShow(e, itemindex){

            if(e && e?.target?.classList?.contains('row-download')){
                return
            }

            if(e && (e?.target?.classList?.contains('row-del') )){
                return
            }
            this.focusId=itemindex
        }
    }


}
</script>

<style lang="scss" scoped>
    .gallery-flow > *{

        display: grid;
        grid-template-columns: 1fr 1fr;

        &.mode4pl{
            grid-template-columns: 1fr 1fr 1fr 1fr;


            > *{
                height: 100px;
                @media (max-width: 500px) {
                    height: calc(25vw - 25px);
                }

            }

        }

        > * {

            position: relative;
            
            height: 200px;
            @media (max-width: 500px) {
                height: calc(50vw - 50px);
            }

            overflow: hidden;
            padding: 2px;
            border: 2px solid black;
            margin: 4px;
            border-radius: 7px;
            box-shadow: 2px 2px 2px #000000af;

            > * {
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
            }
        }
    }

    .gallery-m4-wrap-row{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;


        > *{
            height: 100px;
            @media (max-width: 500px) {
                height: calc(25vw - 25px);
            }

        }

        > *{
            overflow: hidden;
            padding: 2px;
            border: 2px solid black;
            margin: 4px;
            border-radius: 7px;
            box-shadow: 2px 2px 2px #000000af;
            > img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                
            }
        }
    }

    .one-items-line{
        display: block;
        >*{
            width: 75px;
            margin: auto;
        }
    }
    .two-items-line{
        grid-template-columns: 1fr 1fr;
        width: 50%;
        margin: auto;
        grid-column-gap: 5px;
        >*{
            min-width: 80px;
            margin: auto;
        }
    }
    .three-items-line{
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5px;
        width: 75%;
        @media (max-width: 380px) {
            width: 90%;
        }



        margin: auto;
        >*{
            width: 75px;
            margin: auto;
        }
    }

    

    .row-del{
        
        position: absolute;
        right: 0px;
        top: 3px;
        z-index: 1;

        font-size: 22px;
        background-color: rgba(255,255,255,0.85);
        border-radius: 10px 0 0 10px;
        border: 1px solid white;
        align-items: center;
        flex: 0.5;
        align-self: center;
        display: inline-block;
        width: 25px;
        height: 28px;
        vertical-align: top;
        text-align: right;
        &::after{
            color: brown;
            content: "\F057";
            font-family: "Font Awesome 5 Free";
        }
        


        
    }

    .row-show{
        position: absolute;
        right: 0px;
        bottom: 3px;
        z-index: 1;

        font-size: 22px;
        background-color: rgba(255,255,255,0.85);
        border-radius: 10px 0 0 10px;
        border: 1px solid white;
        align-items: center;
        flex: 0.5;
        align-self: center;
        display: inline-block;
        width: 30px;
        max-height: 25px;
        vertical-align: top;
        text-align: right;
        &::after{
            color: gray;
            content: "\f06e";
            font-family: "Font Awesome 5 Free";
        }
        
        
    }

    .row-download{
        
        position: absolute;
        right: 0px;
        bottom: 3px;

        font-size: 22px;
        background-color: rgba(255,255,255,0.85);
        border-radius: 10px 0 0 10px;
        border: 1px solid white;
        align-items: center;

        align-self: center;
        display: inline-block;
        max-width: 20px;
        max-height: 25px;

        vertical-align: top;
        text-align: right;
        &::after{
            color: black;
            opacity: 0.75;
            content: "\f0c6";
            font-family: "Font Awesome 5 Free";
        }
        


        
    }


</style>