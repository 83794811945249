export default function auth ({ next, store }){
    if(!store.getters.isUserAuth){
        return next({
           name: 'login'
        })
    }

    if(!store.getters.isFullRegister){
        return next({
           name: 'welcome'
        })
    }

    return next()
   }