import { MxApi } from "./MxApi"
import { MxStore } from "./MxStore"


export const MxBoss = {

    mixins: [MxStore, MxApi],

    methods:{
   
        async bossGoTeam(team){

            if(+this.whoami.role_id!==14){
                this.$router.push('/team/'+team.id)
                return
            }

            // if(this.whoami.teams && this.whoami.teams.find((t)=>+t.id===team.id)){
            //     this.bossModeCoachTeam = team.id
            // }

            await this.$store.dispatch('updateWhoamiTeam', team.id)
            this.$store.dispatch('setMyteamFlags', team.id)

            

        }     

    },


}