<template>

    <BotMenu menu="jointeam">
        <div class="qw" v-if="team">
            <div class="qw-title">Вступление в команду «{{team.title}}»</div>
            <div class="qw-ico-double">
                <img class="qw-ico" :src="ATT_URL+'pictures/'+whoami.avatar_id" alt="">
                <span class="arrow-join"></span>
                <img class="qw-ico" :src="ATT_URL+'pictures/'+team.logo_id" alt="">
            </div>
            <div class="qw-desc">Вы действительно хотите отправить завяку на вступление в команду? Это может занять некоторое время!</div>
            <a @click.prevent="doJoin" href="#join" class="mdc-button__label black">
                Отправить запрос
            </a>
            <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'jointeam', visible: false})" href="#" class="mdc-button__label">
                Отмена
            </a>

        </div>
    </BotMenu>
</template>

<script>
import BotMenu from '@/components/modal/BotMenu.vue'
import { ATT_URL} from '@/assets/js/config'
import {MxStore} from "@/assets/js/mix/MxStore.js"
import {MxApi} from "@/assets/js/mix/MxApi.js"

export default {
    name: 'JoinTeamModal',
    props:{
      team: {
          type: Object,
          default: () => null
      },        
    },
    computed:{
        ATT_URL: ()=> ATT_URL
    },
    components:{BotMenu},

    mixins: [MxStore, MxApi],


    methods:{
        async doJoin(){
            this.$emit('joinedSended', this.team.id)

            this.$nextTick(async()=>{

                const teams = await this.apiCall('POST', 'team/join', {id: this.team.id})

                if(teams.status===200&&teams.packet.subcode===200){

                    this.$emit('joinedResponsed', this.team.id)
                    
                    
                }
            })

            this.$store.dispatch('toggleBottomMenu', {menu: 'jointeam', visible: false})

        }
    }
}
</script>

<style lang="scss" scoped>

</style>