import {YMAP_APIKEY, API_URL, ATT_URL, BASE_URL} from './config'

const SC_REDIRECTOR_LOGIN = [460, 461, 453]

function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
 }

 async function attApiHttpRequest(_method, _link, _payload = {}){

    return new Promise((resolve, reject) => {

        let xhr = new XMLHttpRequest()

        if(_method==='GET'&&Object.keys(_payload).length){
            xhr.open(_method, ATT_URL+_link +'?'+ encodeQueryData(_payload))
        } else {
            xhr.open(_method, ATT_URL+_link)
        }

        
        
        xhr.onload = function() {
            resolve({status:xhr.status, packet: JSON.parse(xhr.response)})
        };
          
          xhr.onerror = function() { // происходит, только когда запрос совсем не получилось выполнить
            console.log(`Ошибка соединения`);
            reject()
          };
    
        
        if(_method==='PUT'){
            const xcheertoken = localStorage.getItem('xcheertoken')
            if(xcheertoken){
                xhr.setRequestHeader('X-CHEER-TOKEN', xcheertoken);
                xhr.setRequestHeader('X-CHEER-USER', localStorage.getItem('xcheeruser'));
            }
            
        }



        if(_method==='GET'){
            xhr.send()
        } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(_payload))
        }  

    });



}

async function appApiHttpRequest(_method, _link, _payload = {}){
    
    return new Promise((resolve, reject) => {

        let xhr = new XMLHttpRequest()

        if(_method==='GET'&&Object.keys(_payload).length){
            xhr.open(_method, API_URL+_link +'?'+ encodeQueryData(_payload))
        } else {
            xhr.open(_method, API_URL+_link)
        }

        
        
        xhr.onload = function() {
            
            const packet = JSON.parse(xhr.response)

            if(!['auth/login','user/whoami'].includes(_link) && SC_REDIRECTOR_LOGIN.includes(+packet.subcode)){
                internalLocHref(BASE_URL)
                
            }

            resolve({status:xhr.status, packet})
        };
          
          xhr.onerror = function() { // происходит, только когда запрос совсем не получилось выполнить
            console.log(`Ошибка соединения`);
            reject()
          };
    
        
        const xcheertoken = localStorage.getItem('xcheertoken')
        if(xcheertoken){
            xhr.setRequestHeader('X-CHEER-TOKEN', xcheertoken);
        }

        if(_method==='GET'){
            xhr.send()
        } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(_payload))
        }  

    });



}

function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', ATT_URL + url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  
  async function getGeoCode({str, region, city, address, def_city, def_str}){


    let collectCityLoc = false
    let collectRegionLoc = false

    if(!address){

        if(city){

            if(city.location){
                return city.location
            }
            collectCityLoc = city.id

        } else if(region) {

            if(region.location){
                return region.location
            }
            collectRegionLoc = region.id

        } else if(def_city){

            if(def_city.location){
                return def_city.location
            }

            str = def_str

            collectCityLoc = def_city.id
        }

    }

    if(!str){
        return null
    }

    const loc = await  new Promise((resolve)=>{

        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", "https://geocode-maps.yandex.ru/1.x/?format=json&apikey="+YMAP_APIKEY+"&geocode="+str, false ); // false for synchronous request
        xmlHttp.send( null );

        const resp = JSON.parse(xmlHttp.responseText)

        const pos =
            resp.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos

        resolve([
            pos.split(" ")[1],
            pos.split(" ")[0]

        ]);

    })

    if(loc){
        if(collectCityLoc){
            appApiHttpRequest('POST', 'collect/city',{id: collectCityLoc, location: loc})

        } else if(collectRegionLoc){
            appApiHttpRequest('POST', 'collect/region',{id: collectRegionLoc, location: loc})

        }
    }
    
    return loc


}

function hideSplasher(){
    const pl = document.querySelector('body>.pl')
    pl.classList.add('fadeout')
    setTimeout(()=>{
        pl.style.display="none"

    },600)

}

function showSplasher(){
    const pl = document.querySelector('body>.pl')
    pl.classList.remove('fadeout')
    pl.style.display="block"

}

function calcBreadCumbs(sections, id, link, showLast = false){

    
    if(!sections.length){
        return []
    }
    const bc = []
    const cursect = sections.find((s)=>+s.id===+id)

    let last = {}
    if(showLast){
        last = cursect
    } else {
        last = sections.find((s)=>+s.id===+cursect.parent_id)
    }
    
    
    do{

      if(last){
          bc.unshift({link: '/'+link+'/'+last.id, title: last.title})
          
          last = sections.find((s)=>+s.id===+last.parent_id)
          
      }
    }
    while(last)

    return bc
}

function displayHumanDate(d){
    if(!d){
        return d
    }
    return `${d[8]}${d[9]}.${d[5]}${d[6]}.${d[0]}${d[1]}${d[2]}${d[3]}`
}



function displayDate3(date, mode = 0){

    if(date===null){
        date = new Date()
    }

    const objd = new Date(date)



    if(mode===1){
        const months = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(',');
        return `${objd.getDate()} ${months[objd.getMonth()]}` 
    }

    if(mode===2){
        const months = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(',');
        return `${objd.getDate()} ${months[objd.getMonth()]} ${objd.getFullYear()}г.` 
    }

    if(mode===12){
        const months = 'январь,февраль,март,апрель,май,июнь,июль,август,сентябрь,октябрь,ноябрь,декабрь'.split(',');
        return months[objd.getMonth()]
    }

    if(mode===13){
        const months = 'Январь,Февраль,Март,Апрель,Май,Июнь,Июль,Август,Сентябрь,Октябрь,Ноябрь,Декабрь'.split(',');
        return months[objd.getMonth()] + ' ' + objd.getFullYear()
    }

    const YEAR = String(objd.getFullYear()).slice(-2)
    const DAY = ('0'+objd.getDate()).slice(-2)
    const MONTH = ('0'+(1+objd.getMonth())).slice(-2)
    const FULLYEAR = objd.getFullYear()

    const dayofweeks = 'Вс,Пн,Вт,Ср,Чт,Пт,Сб'.split(',');
    const DAYOFWEEK = dayofweeks[objd.getDay()]

    const dayofweeksFull = 'Восресенье,Понедельник,Вторник,Среда,Четверг,Пятница,Суббота'.split(',');
    const DAYOFWEEKFULL = dayofweeksFull[objd.getDay()]


    if(mode==='iso'){
        return `${objd.getFullYear()}-${MONTH}-${DAY}`
        
    }


    const HOUR = ('0'+objd.getHours()).slice(-2)
    const MINUTES = ('0'+objd.getMinutes()).slice(-2)

    if(mode===4){
        return `${DAY}.${MONTH}.${FULLYEAR}`

        
    }

    if(mode===5){
        return `${DAYOFWEEK}, ${DAY}.${MONTH}.${FULLYEAR}`

        
    }


    if(mode===6){
        return `${DAY}.${MONTH}`

        
    }

    if(mode===7){
        return `${DAYOFWEEK}`
    }

    if(mode===8){
        return `${DAYOFWEEKFULL}`
    }


    return `${DAY}/${MONTH}/${YEAR}`
    
  }

function isURL(str) {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  }

function cutUrl(url){
    
    if (typeof url === 'string' || url instanceof String){

        if(url.length>200){
            return url.slice(0, 200) + '...'
        }

    }

    return url
}

function getPlural(count, words, short = false) {
    
    const cases = [2, 0, 1, 1, 1, 2];
    if(short){
        return words[ (count % 100 > 4 && count % 100 < 20) ? 2 : cases[ Math.min(count % 10, 5)] ];

    }
    return count + ' ' + words[ (count % 100 > 4 && count % 100 < 20) ? 2 : cases[ Math.min(count % 10, 5)] ];
}

function getTimezone(){
    const d = new Date()
    let tz = ('0000'+(Math.abs(d.getTimezoneOffset())/6*10)).slice(-4); 
    return (d.getTimezoneOffset()>0)
        ?('-'+tz)
        :('+'+tz)

}



function appShare(shareData){

    if(window.AndroidShareHandler){
        window.AndroidShareHandler.share(
            shareData.title,
            shareData.text,
            shareData.url
        )
    } else if (window?.navigator?.canShare && window.navigator.canShare()){
        window.navigator.share(shareData)
    } else {
        window.navigator.clipboard.writeText(shareData.url)
    }

}


function internalLocHref(url){
    if(window?.AndroidShareHandler?.restartActivity){
        window.AndroidShareHandler.restartActivity()
    } else {
        window.location.href = url
    }
}

export  {
    getTimezone,
    displayHumanDate,
    hideSplasher,
    showSplasher,
    appApiHttpRequest,
    attApiHttpRequest,
    toDataURL,
    getGeoCode,
    calcBreadCumbs,
    isURL,
    cutUrl,
    getPlural,
    displayDate3,
    SC_REDIRECTOR_LOGIN,
    appShare,
    internalLocHref
}