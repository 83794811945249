import { MxApi } from "./MxApi"


export const MxLikes = {

    mixins: [MxApi],

    methods:{
        async setClubLike(club_id){
            const response = await this.apiCall('POST', 'likes/club', {club_id})

            if(response.status===200&&response.packet.subcode===200){
                if (response.packet.data.changed){
                    await this.$store.dispatch('increaseClubLike', {club_id, changed: response.packet.data.changed})
                    return response.packet.data.changed
                    return this.$route.name!=='club-id'?response.packet.data.changed:0
                    
                }


            }
            return 0

        },


        async setTeamLike(team_id){

            const response = await this.apiCall('POST', 'likes/team', {team_id})

            if(response.status===200&&response.packet.subcode===200){
                if (response.packet.data.changed){
                    await this.$store.dispatch('increaseTeamLike', {team_id, changed: response.packet.data.changed})
                    return this.$route.name==='team-id'?response.packet.data.changed:0
                    
                }


            }
            return 0

        },

        async setUserLike(user_id){
            const response = await this.apiCall('POST', 'likes/user', {user_id})

            if(response.status===200&&response.packet.subcode===200){
                if (response.packet.data.changed){
                    // await this.$store.dispatch('increaseTeamLike', {team_id, changed: response.packet.data.changed})
                    return response.packet.data.changed
                    
                }


            }
            return 0        
        }

    },


}