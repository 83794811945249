var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sourceLinks&&_vm.sourceLinks.length)?_c('div',{staticClass:"soclinks"},[_c('div',[_vm._v("Ссылки:")]),(_vm.sourceLinks.filter((val)=>_vm.parseKnown(val)).length)?[_c('div',{staticClass:"icons-list"},[_c('div',{staticClass:"icons-list-wrap"},_vm._l((_vm.sourceLinks.filter((val)=>_vm.parseKnown(val))),function(onelink){return _c('div',{key:onelink,staticClass:"icons-pos"},[_c('a',{staticClass:"icons",class:{
                    vk: _vm.detectVkontakte(onelink),
                    instagram: _vm.detectInstagram(onelink),
                    facebook: _vm.detectFacebook(onelink),
                    telegram: _vm.detectTelegram(onelink),
                    youtube: _vm.detectYoutube(onelink),
                },attrs:{"href":onelink,"target":"_blank"}})])}),0)])]:_vm._e(),_vm._l((_vm.sourceLinks.filter((val)=>_vm.parseUnknown(val))),function(onelink){return _c('div',{key:onelink},[_c('a',{staticClass:"onelink ico-globus",attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('toggleBottomMenu', {menu: 'extsite', visible: true, extSiteAddr: onelink})}}},[_vm._v(_vm._s(onelink))])])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }