<template>
    
    <BotMenu menu="quit">
        <div class="qw">
            <div class="qw-title">Выйти</div>
            <div class="qw-desc">Вы действительно хотите выйти из приложения?</div>
            <a @click.prevent="doLogout" href="/exit" class="mdc-button__label black">
                Выход
            </a>
            <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'quit', visible: false})" href="#" class="mdc-button__label">
                Отмена
            </a>

        </div>
    </BotMenu>
</template>


<script>
import BotMenu from '@/components/modal/BotMenu.vue'
import { MxApi } from '@/assets/js/mix/MxApi'
import { MxStore } from '@/assets/js/mix/MxStore'

export default {
    name: 'QuitModal',
    components:{BotMenu},

    mixins: [MxApi, MxStore],

    methods:{
        async doLogout(){

            const teamNew = await this.apiCall('POST', 'auth/logout', 
                {}
            )

            localStorage.removeItem('xcheertoken')

            this.internalLocHref('/')
            

        },
    }
}
</script>
