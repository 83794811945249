<template>
    <div class="layout">
        
        <header>
            <NavBar />
        </header>


        <main
          v-if="globalHomeSpinner"
          :class="userOptions.background"
          :style="'display: grid; height: 100vh;'"
        >
            <Spinner />
        </main>
        <main
          v-else
          id="idmainloilc"
          :class="userOptions.background">

            <div class="userbar" :class="{
                'hidden-half': ['gymcat', 'gympos', 'shopcat', 'shoppos', 'news', 'newsread'].includes($route.name),
                'hidden-full': !['home', 'gymcat', 'gympos', 'shopcat', 'shoppos', 'news', 'newsread', 'findteam', 'newteam', 'newclub'].includes($route.name)
              }">

            <NavProfile />
            <NavPages  />

            </div>

            <div
              v-if="startingLoaded"
              class="slot-con" >
              <slot></slot>
            </div>
            

            <footer
              v-if="![
                  'teamchat',
                  'clubchat'

              ].includes($route.name)"
            >
                <img src="@/assets/img/fa/logo_gorizontal_white 1.svg">
            </footer>

        </main>

        <ProfileSettings />

        <TeamSettings />

        <ClubSettings v-if="+whoami.role_id===14" />

        <BotMenu menu="extsite">
            <div class="qw">
                <div class="qw-title">Открыть ссылку</div>
                <div class="qw-desc">Вы действительно хотите открыть ссылку "{{cutUrl(flVisibleBottom.extsite.extSiteAddr)}}"?</div>
                <a :href="flVisibleBottom.extsite.extSiteAddr" target="_blank" rel="nofollow" class="mdc-button__label black">
                    Перейти
                </a>
                <a @click.prevent="$store.dispatch('toggleBottomMenu', {menu: 'extsite', visible: false})" href="#" class="mdc-button__label">
                    Отмена
                </a>

            </div>
        </BotMenu>

        <LeaverModal />

    </div>
</template>




<script>
import {MxStore} from '@/assets/js/mix/MxStore.js'
import NavBar from "@/components/NavBar.vue"
import Spinner from "@/components/Spinner.vue"
import NavProfile from "@/components/NavProfile.vue"
import NavPages from "@/components/NavPages.vue"
import VetrineTeam from "@/components/VetrineTeam.vue"
import VetrineClub from "@/components/VetrineClub.vue"
import NewsItem from "@/components/NewsItem.vue"
import ProfileSettings from "@/components/ProfileSettings.vue"
import TeamSettings from "@/components/TeamSettings.vue"
import ClubSettings from "@/components/ClubSettings.vue"
import BotMenu from '@/components/modal/BotMenu.vue'
import LeaverModal from '@/components/modal/LeaverModal.vue'

export default {
    components:{
        NavBar,
        NavProfile,
        NavPages,
        ProfileSettings,
        VetrineTeam,
        VetrineClub,
        TeamSettings,
        ClubSettings,
        NewsItem,
        BotMenu,
        LeaverModal,
        Spinner
    },
  mixins:[MxStore],
  mounted(){

    this.$store.dispatch('updateOurCount')
  }
}
</script>

<style scoped lang="scss">

    header{
      background-color: black;
    }

    .layout{
        display: grid;
        grid-template-rows: 42px 5fr;

    }

    main{
        max-height: calc(100vh - 42px);
        overflow: auto;

        // background-image: url(@/assets/img/bg/bg7.jpeg);

    }

    .our{
        text-align: center;
        padding: 12px 0;
        background-color: aqua;
        margin: 30px 0 20px 0;

        font-family: 'Montserrat';
        font-weight: 500;
        text-transform: uppercase;
        box-shadow: 1px 0 12px #333, 0 0px 2px #333, -1px 0 4px #333, 0 1px 4px #333;

    }


footer{
    margin-top: 50px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    text-align: center;
    background-color: #714f83;
    // background-color: lightgray;
    
}

.slot-con{
  min-height: calc(100vh - 40px);
}

.wrap-newsitem{
  padding: 0 15px;
}


.userbar{
  margin-top: 0;

  transition-property: margin-top;
  transition-duration: 0.3s;

  &.hidden-full{
        margin-top: -180px;

  }

  &.hidden-half{
        margin-top: -95px;

  }
}

</style>