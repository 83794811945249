<template>
  <nav class="nav-bar" v-if="$store">
    
    <div class="logo">
      <a href="/" @click.prevent="goHome">
        <img src="../assets/img/fa/logo_gorizontal_white 1.svg">
      </a>
      
    </div>
    <RoleSelect
      v-if="allowRoles.length>1"
      :roles="allowRoles"
      :currentId="currentRole"
      :doChangeVal="doChangeRole"
    />
    <div v-if="allowRoles.length === 1"><span class="alone-role">{{allowRoles[0].short}}</span></div>
    <div class="tool-buttons-wrap">
        <div class="tool-buttons">

          <div class="nav-btn-wrap mr15">
            <router-link class="nav-btn nav-btn-crown" :class="{'is-premium': premiumAccess}" to=""></router-link>
          </div>

          <div class="nav-btn-wrap">
            <router-link class="nav-btn nav-btn-quest" to="/faq/5"></router-link>
          </div>

          <div class="nav-btn-wrap">
            <a class="nav-btn nav-btn-profile" href="#" @click.prevent="$store.dispatch('toggleProfileMenu', !flOpenedProfileMenu)"></a>
          </div>

        </div>
    </div>

  </nav>
</template>

<script>
import RoleSelect from "@/components/RoleSelect.vue"
import {MxStore} from "@/assets/js/mix/MxStore.js"
import { MxApi } from '@/assets/js/mix/MxApi'

export default {
  name: 'NavBar',
    mixins:[MxStore, MxApi],



  components:{
    RoleSelect
  },

  data: ()=>({
    currentRole: null
  }),

  mounted(){
    this.currentRole = +this.whoami.role_id
  },

  watch:{
    '$store.state.whoami.role_id'(val){
      this.currentRole = +val
    }
  },

  methods:{
    async goHome(){


      const c = new Date()
      await this.$router.push('/home?'+c.getTime())

      if(this.whoami && +this.whoami.role_id===14){
        await this.$store.dispatch('bossModeCoachTeam', null)
        await this.$store.dispatch('whoami')
      }

      // WA-168 - сброс закрепленных
      if([15,16,18].includes(+this.whoami.role_id)){
        if(this.userOptions.pinTeam && +this.userOptions.pinTeam !== +this.focusTeamId){
            const valid = (this.whoami.teams ?? []).find(i => +i.id === +this.userOptions.pinTeam)
            if(valid){
                this.$store.dispatch('setFocusTeamId', this.userOptions.pinTeam)
            }
          
        }
      }
      if([14].includes(+this.whoami.role_id)){
        if(this.userOptions.pinClub && +this.userOptions.pinClub !== +this.focusClubId){
            const valid = (this.whoami.clubs ?? []).find(i => +i.id === +this.userOptions.pinClub)
            if(valid){
                this.$store.dispatch('setFocusClubId', this.userOptions.pinClub)
            }
          
        }
      }

      


    },

    async doChangeRole(newRoleId){
      this.currentRole=newRoleId

      await this.$store.dispatch('globalHomeSpinner', true)

      const response = await this.apiCall('POST', 'user/changerole', {role_id: newRoleId})
      if(response.status===200&&response.packet.subcode===200){

          await this.$store.dispatch('bossModeCoachTeam', null)
          await this.$store.dispatch('whoami')

          if(this.$route.name!=='home'){

            this.$router.push('/')
          }

          this.$nextTick(()=>{
            this.$store.dispatch('setFocusTeamId', null)

            this.$store.dispatch('globalHomeSpinner', false)

          })


      }

      


    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .tool-buttons-wrap *{
    box-sizing: content-box;
  }

  nav{
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    background-color: #714f83; 

    >*{
      flex: 1;
    }
    
  }
  .logo{
    margin-left: 15px;
    height: 40px;
    text-align: center;
    max-width: 80px;


    @media (max-width: 399px) {
        margin-left: 3px;
    }

    @media (max-width: 359px) {
        margin-left: 0px;
    }

  }
  .nav-btn-wrap{
    padding: 6px;
    width: 28px;
    height: 28px;
    &.mr15{
        margin-right: 5px;
    }
  }

  .tool-buttons{
    display: flex;
    justify-content: flex-end;

    max-width: 110px;
    margin-left: auto;

  }

  .nav-btn{
    width: 100%;
    height: 100%;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    text-decoration: none;
    // &.nav-btn-crown{
    //   background-image: url("@/assets/img/fa/Group 343.svg");
    // }

    // &.nav-btn-quest{
    //   background-image: url("@/assets/img/fa/Group 342.svg");
    // }

    // &.nav-btn-profile{
    //   background-image: url("@/assets/img/fa/Group 341.svg");
    // }

  }

  img{
    transform: scale(1.25);
  }


  .nav-btn{
        font-family: "Font Awesome 5 Free";
        font-size: 150%;
        color: white;
  }

  .nav-btn-crown{
        &.is-premium{
          color: gold;
        }
        &::before{
                content: "\f521";
                font-family: "Font Awesome 5 Free";
                box-sizing: content-box;
              

          
          }

  }
    .nav-btn-quest{
      width: 23.25px;
      height: 24px;

      &::before{
        content: "\f059";
        font-family: "Font Awesome 5 Free";
        box-sizing: content-box;
      }


  }
    .nav-btn-profile{
    
        width: 23.25px;
        height: 24px;
        &::before{

          content: "\f2bd";
          font-family: "Font Awesome 5 Free";
          box-sizing: content-box;
        }

  }




.alone-role{
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    font-weight: 900;
    padding: 8px 18px 8px 10px;
    width: 155px;
    font-size: 13.3333px;
    white-space: nowrap;
}

</style>
