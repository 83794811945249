import { render, staticRenderFns } from "./JoinClubModal.vue?vue&type=template&id=4712d561&scoped=true&"
import script from "./JoinClubModal.vue?vue&type=script&lang=js&"
export * from "./JoinClubModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4712d561",
  null
  
)

export default component.exports